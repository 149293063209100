import React from 'react';
import PropTypes from 'prop-types';

import quoteStyles from '../../styles/quote.module.css';
import {iconography} from '../../styles/typography.module.css';

/**
 * @description Displays quote component with caption and image.
 * @param {string} quote Actual quote text.
 * @param {string} caption Caption text.
 * @return {DOMElement} Quote React element.
 */
const Quote = ({quote, caption}) => {

  return (
    <div className={`rc-margin-bottom--xl--mobile rc-margin-bottom--xl--desktop ${quoteStyles.wrapper}`}>
      <div className="rc-md-up rc-margin-right--md">
        <figure className={`rc-img--round rc-img--round--md ${quoteStyles.image}`}>
          <figcaption className="rc-screen-reader-text">Quote</figcaption>
        </figure>
      </div>
      <div>
        <div className={`rc-gamma rc-margin-bottom--lg--mobile ${iconography}`}>{`"${quote}"`}</div>

        <div className={`rc-margin-bottom--lg--mobile rc-margin-bottom--xl--desktop ${quoteStyles.captionWrapper}`}>
          <div className="rc-md-down rc-margin-right--md">
            <figure className={`rc-img--round ${quoteStyles.image}`}>
              <figcaption className="rc-screen-reader-text">Quote</figcaption>
            </figure>
          </div>
          <div className="rc-intro">-- {caption}</div>
        </div>

      </div>
    </div>
  );
};

Quote.propTypes = {
  quote: PropTypes.string,
  caption: PropTypes.string
};

export {Quote};
