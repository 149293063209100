/**
 * @description Detect if user is browsing in Internet Explorer or Edge browser.
 * Detects browser's version as well.
 * @return {object} Object with browser's name and version.
 */
const detectIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  let browser = {};
  if (msie > 0) {
    // IE 10 or older => return version number
    browser.name = 'ie';
    browser.version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    return browser;
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    browser.name = 'ie';
    browser.version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    return browser;
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    browser.name = 'edge';
    browser.version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    return browser;
  }
  // other browser
  return false;
};

export default detectIE;
