import React from 'react';
import {Transition} from 'react-transition-group';
import PropTypes from 'prop-types';

/**
 * @description Component that allows animation on mount/unmount using react-transition-group.
 * @param {boolean} visible Defines if curretn component is visible or not.
 * @param {DOMElement} children Any valid react children of component.
 * @param {object} additionalStyle Any addtional styles passed to component.
 * @return {DOMElement} Fade React element.
 */

const Fade = ({visible, children, additionalStyle}) => {

  const duration = 300;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
  };
  const transitionStyles = {
    entering: {opacity: 1},
    entered: {opacity: 1, zIndex: 10}, 
    exiting: {opacity: 0},
    exited: {opacity: 0}
  };

  return (
    <Transition in={visible} timeout={duration}>
      {state => (
        <div style={{
          ...defaultStyle,
          ...transitionStyles[state],
          ...additionalStyle
        }}>
          {children}
        </div>
      )}
    </Transition>
  );
};
Fade.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  additionalStyle: PropTypes.object
};

export {Fade};
