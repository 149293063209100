import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description Single rating component. Displays properly styled icons to visually show rating.
 * @param {number} rate Rating number.
 * @param {number} max Maximum possible rating.
 * @return {DOMElement} Rating React element.
 */
const Rating = ({rate = '5', max = '5'}) => {

  /**
   * @description Render array of icons with correct styling.
   * @return {array} Single icon element.
   */
  const renderIcons = () => {
    let icons = [];
    for (let i = 1; i <= max; i++) {
      let iconColour = i > rate ? 'rc-iconography--xs' : 'rc-brand1--xs';
      icons.push(
        <span
          key={i}
          className={`rc-icon rc-badge--icon-label rc-padding-x--xs rc-margin-right--xs rc-rate-fill--xs
          ${iconColour}`}
        />
      );
    }
    return icons.map(icon => {
      return icon;
    });
  };

  return <>{renderIcons()}</>;
};

Rating.propTypes = {
  rate: PropTypes.number,
  max: PropTypes.number
};

export {Rating};
