import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Tab} from '.';

/**
 * @description RCDL tabs component.
 * @param {array} children Array with valid DOM elements to display as a tab conent.
 * @param {string} contentClass Class to pass to content container.
 * @return {DOMElement} Tabs React element.
 */
const Tabs = ({children, contentClass, activeCallback = () => {}, activeTabFromParent}) => {

  let [activeTab, setActiveTab] = useState(children[0].props.id);

  const handleClick = (id) => {
    setActiveTab(id);
    activeCallback(id);
  }

  /**
   * @description Renders tabs navigation items.
   * @return {array} Array with tabs navigation items.
   */
  const renderTabs = () => {
    return children.map((child, i) => {
      return (
        <Tab
          key={i}
          value={child.props.id}
          label={child.props.label}
          onClick={handleClick}
          activeTab={activeTab}/>
      );
    });
  };

  /**
   * @description Renders content for currentle selected tab.
   * @return {array} Array with current tab block content element.
   */
  const renderContent = () => {
    return children.filter(child => child.props.id === activeTab);
  };

  useEffect(() => {
    setActiveTab(activeTabFromParent);
  }, [activeTabFromParent]);

  return (
    <div>
      <nav className="rc-tabs__controller rc-tabs__controller--border--full-width">
        <ul
          className="rc-scroll--x rc-list rc-list--inline rc-list--align rc-list--blank rc-tab--centered"
          role="tablist">
          {renderTabs()}
        </ul>
      </nav>
      <div className={contentClass ? contentClass : 'rc-max-width--lg'}>
        {renderContent()}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  contentClass: PropTypes.string,
  activeCallback: PropTypes.func
};

export {Tabs};
