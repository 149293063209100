
/**
 * @description Used to add/remove classes on a target element.
 * @param {string} type Modify class type, accepts toggle, add or remove.
 * @param {DOMElement} target Targeted DOM node item.
 * @param {string} className Class name to be toggled.
 */
function modifyClass(type, target, className) {
  if (target !== null) {
    if (type === 'toggle') {
      let hasClass = null;
      let addRemove = null;

      if (target.classList) {
        hasClass = target.classList.contains(className);
      }
      else {
        hasClass = new RegExp('(^| )' + className + '( |$)', 'gi').test(target.className);
      }

      switch (hasClass) {
        case true:
          addRemove = 'remove';
          break;

        case false:
          addRemove = 'add';
          break;

        default:
          throw new Error('Has Class option used with method window.RCDL.utilities.toggleClass is invalid.');
      }

      // IE 8+ support.
      if (target.classList) {
        target.classList[addRemove](className);
      }
      else {
        if (addRemove === 'add') {
          target.className += className;
        }
        else {
          target.className = target.className.replace(
            new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
      }
    }

    else if (type === 'add') {
      if (target.classList) {
        if (typeof className === 'string') {
          target.classList.add(className);
        }
        else {
          className.forEach(function (name) {
            target.classList.add(name);
          });
        }
      }
      // IE 8+ support.
      else {
        let currentClassName = target.getAttribute('class');
        let newClassName = currentClassName !== null ? `${currentClassName} ${className}` : className;

        target.setAttribute('class', newClassName);
      }
    }

    else if (type === 'remove') {
      if (target.classList) {
        if (typeof className === 'string') {
          target.classList.remove(className);
        }
        else {
          className.forEach(function (name) {
            target.classList.remove(name);
          });
        }
      }
      // IE 8+ support.
      else {
        target.className = target.className.replace(
          new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    }

    else {
      throw new Error('Class modifier is invalid. Accepts toggle, add or remove');
    }
  }
  else {
    throw new Error(`Null nodeElement sent to window.RCDL.utilities.modifyClass. 
    Class name passed: ${className}. 
    Modifier type ${type}.
    Stack trace: ${console.trace()}`); // eslint-disable-line no-console
  }
}

export default modifyClass;
