import React, {useLayoutEffect, useState, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {TooltipsContext} from '../../contexts/tooltipsContext';
import blockStyles from '../../styles/block.module.css';
import journeyViewsStyles from '../../styles/journeyViews.module.css';
import detectIE from '../../helpers/detectIE';
import {JourneySections, DiagramTooltips} from '.';
import {RCDLStateContext} from '../../contexts/RCDLcontext';
import {RCDL_VERSION} from '../../config';

/**
 * @description Component that displays diagram journey SVG element.
 * @param {function} handleIntersection Method to be invoked when intsersection observer is fired.
 * @param {string} currentStage Name of current stage.
 * @param {object} diagramData Data object containg svg inoformation.
 * @param {number} pageWidth Current viewport width.
 * @param {array} tooltips Array of objects with tooltips data.
 * @param {array} tooltipsJourney Array of objects with tooltips data.
 * @param {array} views Array of objects with information about views to display.
 * @param {function} setCurrentView Function to setup currentView state in parent component.
 * @param {string} currentView State that indicates which view supposed to be active/current.
 * @return {DOMElement} Journey Diagram React element.
 */

const JourneyDiagramSvg = ({
  handleIntersection,
  currentStage,
  diagramData,
  pageWidth,
  tooltips,
  tooltipsJourney,
  views,
  setCurrentView,
  currentView
}) => {

  if (typeof window === 'undefined') { // Ensure proper server rendering.
    return null;
  }
  let [svgLoaded, setSvgLoadStatus] = useState(false);
  const [tooltipsInstances, setTooltipsInstances] = useContext(TooltipsContext);
  const viewContainer = useRef(null);
  const mutationObserverElements = {};
  views.forEach(view => {
    mutationObserverElements[view.value] = useRef(null);
  });

  const mutationConfig = {attributes: true, childList: true, subtree: true};
  let browser = {name: 'notIE', version: 'new'}; // ToDo: refactor into global function.


  if (typeof window !== 'undefined') {
    browser = detectIE();
  }

  const handleSwipe = (direction) => {
    if (direction === 'right' && currentView === 'owner-feelings') {
      setCurrentView('owner-journey', tooltipsInstances);
    }
    if (direction === 'left' && currentView === 'owner-journey') {
      setCurrentView('owner-feelings', tooltipsInstances);
    }
  };

  /**
   * @description Handles behaviour when mutation observer detects any change.
   * @param {array} mutationsList Array with data about detected mutations.
   */
  const handleMutation = mutationsList => { // TODO: Move to separate mutation component
    if (browser.name === 'ie' && browser.version === 11) {
      Object.keys(mutationObserverElements).forEach(element => {
        if (mutationObserverElements[element].current.children[0] !== null) {
          const svg = mutationObserverElements[element].current.children[0];
          if (svg.getAttribute('data-done') === null) {
            const ratio = svg.clientWidth / svg.clientHeight;
            svg.setAttribute('data-done', 'true');
            svg.setAttribute('width', svg.parentNode.clientWidth + 'px');
            svg.setAttribute('height', svg.parentNode.clientWidth * ratio + 'px');
            const styleTags = svg.querySelectorAll('style');

            Array.from(styleTags).forEach(styleTag => {
              styleTag.textContent += '';
            });
          }
        }
      });
    }
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList' && Array.from(mutation.addedNodes).length > 0) {
        setSvgLoadStatus(true);
      }
    }
  };

  const journeyImageData = pageWidth >= RCDL.config.breakpoints['xl']
    ? diagramData.journeyLargeImage
    : diagramData.journeySmallImage;
  const feelingsImageData = pageWidth >= RCDL.config.breakpoints['xl']
    ? diagramData.feelingsLargeImage
    : diagramData.feelingsSmallImage;

  useLayoutEffect(
    () => {
      Object.keys(mutationObserverElements).forEach(element => {
        const observer = new MutationObserver(handleMutation);
        if (mutationObserverElements[element].current !== null) {
          observer.observe(mutationObserverElements[element].current, mutationConfig);
        }
      });

      if (viewContainer) {
        import('hammerjs').then(Hammer => {
          const hammerHandler = new Hammer.default(viewContainer.current);
          hammerHandler.on('swipe', (e) => {
            if (e.direction === 2) {
              handleSwipe('left');
            }
            else if (e.direction === 4) {
              handleSwipe('right');
            }
          });
        });

      }
    }
  );

  const renderViews = () => { // TODO: Move that to seperate component.
    return views.map((view, i) => {
      let addtionalClass = i > 0 ? journeyViewsStyles.absoluteDiagram : '';
      const moveClass = i > 0 ? journeyViewsStyles.moveRight : journeyViewsStyles.moveLeft;
      addtionalClass = `${addtionalClass} ${currentView === view.value
        ? journeyViewsStyles.moveCenter
        : moveClass}`;
      return (
        <div
          key={view.value}
          id={view.value}
          label={view.label}
          className={`${journeyViewsStyles.move} ${addtionalClass}`}>
          <div ref={mutationObserverElements[view.value]}>
            <object
              data={view.value === 'owner-journey' ? journeyImageData.file.url : feelingsImageData.file.url}
              className={`journey-diagram-svg ${blockStyles.displayBlock}`}
              type="image/svg+xml"
              data-js-import-interactive-svg>
              <img
                src={`https://d1a19ys8w1wkc1.cloudfront.net/1x1.gif?v=${RCDL_VERSION}`}
                style={{
                  backgroundImage: `url(${view.value === 'owner-journey'
                    ? journeyImageData.file.url
                    : feelingsImageData.file.url}})`
                }}/>
            </object>
          </div>
        </div>
      );
    });
  };

  return (
    <div ref={viewContainer}>
      <div style={{position: 'relative', overflow: 'hidden'}}>
        {svgLoaded && tooltips && tooltipsJourney && (
          <div style={{position: 'absolute', top: 0, height: '100%', width: '100%'}}>
            <RCDLStateContext.Consumer>
              {(RCDLState) => {
                return (
                  <DiagramTooltips
                    RCDLState={RCDLState[0]}
                    tooltips={tooltips}
                    tooltipsJourney={tooltipsJourney}
                    screenSize={pageWidth}
                    show={currentView === 'owner-feelings'}/>
                );
              }}
            </RCDLStateContext.Consumer>
          </div>
        )}
        <div style={{position: 'absolute', top: 0, height: '100%', width: '100%', zIndex: -1}}>
          {svgLoaded && <JourneySections currentStage={currentStage} handleIntersection={handleIntersection}/>}
        </div>
        {renderViews()}
      </div>
    </div>
  );
};

JourneyDiagramSvg.propTypes = {
  handleIntersection: PropTypes.func,
  currentStage: PropTypes.string,
  diagramData: PropTypes.object,
  pageWidth: PropTypes.number,
  lifestageName: PropTypes.string,
  species: PropTypes.string,
  tooltips: PropTypes.array,
  tooltipsJourney: PropTypes.array,
  views: PropTypes.array,
  setCurrentView: PropTypes.func,
  currentView: PropTypes.string
};

export {JourneyDiagramSvg};
