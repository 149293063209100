import React from 'react';
import '../../styles/quant-stars.css';

import PropTypes from 'prop-types';

/**
 * @description Wraper for displaying rating title and graphic representation.
 * @return {DOMElement} Title Rating React element.
 */
const QuantInsightStars = ({data}) => {

  let items = [];

  data.columns.forEach(column => {
    column.statementListItem.map(({statementListItemText, statementListItemMedia}, keyValue) => {
      items.push(
        <div key={keyValue} className="rc-column">
          <object data={statementListItemMedia.file.url} type="image/svg+xml"></object>
          <p>{statementListItemText}</p>
        </div>
      );
    });
  });

  return (
    <>
      <div className="rc-layout-container rc-one-column rc-max-width--xl rc-text--center rc-padding-bottom--md rc-padding-top--md">
        <div className="rc-column block-module--reverse-column-mobile">
          <div className="rc-layout-container rc-five-column rc-max-width--lg">
            {items}
          </div>
        </div>
      </div>
    </>
  );
};

QuantInsightStars.propTypes = {
  data: PropTypes.object
};

export {QuantInsightStars};
