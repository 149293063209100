import React from 'react';
import PropTypes from 'prop-types';

import joinClassNames from '../../helpers/joinClassNames';

/**
 * @description Column component to place inside of layout container.
 * @param {DOMElement} children Any valid DOM element.
 * @param {string} align Defines how column content should be vertically aligned.
 * @param {string} size Defines the size of columns, to match RCDL rules.
 * @param {string} additionalClass Any Addtional classes.
 * @param {string} customTag Passed if columns should something else then div.
 * @param {any} rest Any other props.
 * @return {DOMElement} Column React element.
 */
const Column = ({children, align, size, additionalClass = '', customTag, ...rest}) => {

  const alignClass = align ? ` rc-self-h-${align}` : '';
  const sizeClass = size ? ` rc-${size}-width` : '';
  const fullClass = joinClassNames([alignClass, sizeClass, additionalClass]);
  const Tag = customTag ? `${customTag}` : 'div';

  return (
    <Tag className={`rc-column ${fullClass}`} {...rest}>
      { children }
    </Tag>
  );
};

Column.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  align: PropTypes.oneOf(['top', 'bottom', 'middle']),
  size: PropTypes.oneOf(['double', 'triple', 'quad']),
  additionalClass: PropTypes.string,
  customTag: PropTypes.string
};

export {Column};
