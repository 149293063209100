import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

const Select = ({options, id = 'id-select', name, onChange, label = '', additionalClass}) => {
  let inputClass = `rc-select ${additionalClass}`;
  const selectElement = useRef(null);
  let [isProcessed, setProcessed] = useState(false);

  useEffect(() => {
    import('choices.js').then((Choices) => {
      new Choices.default(selectElement.current, {
        searchEnabled: false,
        itemSelectText: ''
      });
      setProcessed(true);
    });
  }, []);

  const renderOptions = () => {
    return options.map((option, i) => {
      return (
        <option key={`${option}--${i}`} value={option.value} {...option.attributes}>{option.label}</option>
      );
    });
  };

  return (
    <span className={`${inputClass} ${isProcessed ? 'rc-select-processed' : ''}`}>
      <label className="rc-select__label" htmlFor={id}>{label}</label>
      <select
        ref={selectElement}
        id={id}
        onChange={onChange}
        value={options[0].value}
        name={name}
        style={{display: 'none'}}> {/* TODO:Fix display none - choices.js should
        hide that by default - to investigate */}
        {renderOptions()}
      </select>
    </span>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  additionalClass: PropTypes.string
};

export {Select};

