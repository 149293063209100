import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import typographyStyles from '../../styles/typography.module.css';
import journeyViewsStyles from '../../styles/journeyViews.module.css';
import {Tooltip} from '../organisms';
import modifyClass from '../../helpers/modifyClass';

/**
 * @description Displays tooltip buttons on the owner journey diagram.
 * @param {array} tooltips Array with data for tooltips like id, content and positioning.
 * @param {array} tooltipsJourney Array with data for tooltips like id, content and positioning.
 * @param {number} screenSize Current viewport width.
 * @param {object|array} RCDLState Object that describes load state of RCDL assets.
 * @param {boolean} show Defines if tooltips supposed to be showed or hidden.
 * @return {DOMElement} Diagram Tooltips React element.
 */
const DiagramTooltips = ({tooltips, tooltipsJourney, screenSize, RCDLState, show}) => {


  const handleShow = (e) => {
    modifyClass('add', e.reference, 'rc-minus--xs');
    modifyClass('remove', e.reference, 'rc-plus--xs');
  };

  const handleHide = (e) => {
    modifyClass('remove', e.reference, 'rc-minus--xs');
    modifyClass('add', e.reference, 'rc-plus--xs');
  };

  /**
   * @description Renders tooltips buttons and content.
   * @return {array} Array with tooltip elements.
   */
  const renderTooltips = () => {
    return tooltips.map(({
      tooltipId,
      title,
      tooltipText,
      positionXDesktop,
      positionXMobile,
      positionYDesktop,
      positionYMobile,
      icon = 'plus'}) => {
      const left = screenSize >= RCDL.config.breakpoints['xl'] ? positionXDesktop : positionXMobile;
      const top = screenSize >= RCDL.config.breakpoints['xl'] ? positionYDesktop : positionYMobile;
      return (
        <Tooltip
          key={tooltipId}
          id={tooltipId}
          theme="brand2"
          buttonStyles = {{
            top: `${top}%`,
            right: 'auto',
            bottom: 'auto',
            left: `${left}%`,
            position: 'absolute'
          }}
          onShow={handleShow}
          onHide={handleHide}
          className={`rc-btn rc-btn--action rc-icon
          rc-brand3 rc-${icon}--xs rc-interactive-button rc-interactive-image__button`}
          data-tooltip-colour="brand2 rc-brand"
          data-js-left={`${left}%`}
          data-js-top={`${top}%`}
          data-js-icon-button=""
          data-tooltip-placement="top"
          data-tooltip={tooltipId}
          aria-label="Plus"
          interactiveButton
          button={
            <>
              <div
                className={`rc-md-up ${journeyViewsStyles.tooltipLabel} ${left > 50
                  ? journeyViewsStyles.tooltipLabelRight
                  : journeyViewsStyles.tooltipLabelLeft }`}>
                {title}</div>
              <span className="rc-screen-reader-text">{title}</span>
              </>
          }>
          <div className="rc-text--left rc-padding--xs">
            <span className={`rc-espilon ${typographyStyles.brand3}
              ${typographyStyles.uppercase}`}><b>{title}</b></span>
            <p className="rc-margin-top--xs">{tooltipText.tooltipText}</p>
          </div>
        </Tooltip>
      );
    });
  };

  const renderJourneyTooltips = (notabutton) => {
    return tooltipsJourney.map(({
      tooltipId,
      title,
      tooltipText,
      positionXDesktop,
      positionXMobile,
      positionYDesktop,
      positionYMobile,
      icon = 'plus'}) => {
      const left = screenSize >= RCDL.config.breakpoints['xl'] ? positionXDesktop : positionXMobile;
      const top = screenSize >= RCDL.config.breakpoints['xl'] ? positionYDesktop : positionYMobile;
      return (
        <Tooltip
          notabutton={notabutton}
          key={tooltipId}
          id={tooltipId}
          theme="brand2"
          buttonStyles = {{
            top: `${top}%`,
            right: 'auto',
            bottom: 'auto',
            left: `${left}%`,
            position: 'absolute',
            height:'115px',
            width:'115px'
          }}
          onShow={handleShow}
          onHide={handleHide}
          className={`rc-btn rc-btn--action rc-icon
          rc-brand3 rc-${icon}--xs rc-interactive-button rc-interactive-image__button`}
          data-tooltip-colour="brand2 rc-brand"
          data-js-left={`${left}%`}
          data-js-top={`${top}%`}
          data-js-icon-button=""
          data-tooltip-placement="top"
          data-tooltip={tooltipId}
          aria-label="Plus"
          interactiveButton
          button={
            <>
              <div
                className={`rc-md-up ${journeyViewsStyles.tooltipLabel} ${left > 50
                  ? journeyViewsStyles.tooltipLabelRight
                  : journeyViewsStyles.tooltipLabelLeft }`}>
                {title}</div>
              <span className="rc-screen-reader-text">{title}</span>
              </>
          }>
          <div className="rc-text--left rc-padding--xs">
            <span className={`rc-espilon ${typographyStyles.brand3}
              ${typographyStyles.uppercase}`}><b>{title}</b></span>
            <p className="rc-margin-top--xs">{tooltipText.tooltipText}</p>
          </div>
        </Tooltip>
      );
    });
  };

  useEffect(() => {
    if (typeof RCDLState !== 'undefined') {
      if (RCDLState.RCDLloaded) {
        RCDL.features.iconButtons('[data-js-icon-button]');
      }
    }
  }, []);

  return (
    <>
      <div
        className={`rc-interactive-image ${show ? journeyViewsStyles.moveCenter : journeyViewsStyles.moveRight}`}
        style={{position: 'absolute', transition: '0.2s'}}>
        {renderTooltips()}
      </div>
      <div
        className={`rc-interactive-image ${show ? journeyViewsStyles.moveRight : journeyViewsStyles.moveCenter}`}
        style={{position: 'absolute', transition: '0.2s'}}>
        {renderJourneyTooltips(true)}
      </div>
    </>
  );

};

DiagramTooltips.propTypes = {
  tooltips: PropTypes.array,
  tooltipsJourney: PropTypes.array,
  screenSize: PropTypes.number,
  RCDLState: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  show: PropTypes.bool
};

export {DiagramTooltips};
