import React, {useState} from 'react';

import {LayoutContainer, Column} from '../layouts';
import {TextField, Button, LogoRC, Image} from '../common';
import useForm from '../../helpers/useForm';
import sendUrlRequest from '../../helpers/sendUrlRequest';
import {useStaticQuery, graphql} from 'gatsby';
import {flexColumnMobile} from '../../styles/block.module.css';
import {RCDLStateContext} from '../../contexts/RCDLcontext';

/**
 * @description Login page layout. Handles sending login request.
 * @return {DOMElement} Login React element.
 */
const Login = () => {

  let [requestStatus, updateRequestStatus] = useState({});
  const {values, handleChange, handleSubmit} = useForm(handleLogin);
  function handleLogin() {
    sendUrlRequest(values.email, window.location.origin)
      .then((data) => {
        return updateRequestStatus({
          isSent: true,
          isEmailCorrect: data.emailCorrect
        });
      });
  }

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulAsset(
          filter: {
            title: {
              eq: "login-image"
            }
          }
        ) {
          edges {
            node {
              title
              fluid {
                base64 
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(width: 800, height: 600) {
                src
                width
                height
              }
            }
          }
        }
      }
    `
  );

  return (
    <div style={{height: '100vh'}}>
      <div className="rc-text--center" style={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        right: 0}}>
        <RCDLStateContext.Consumer>
          {(context) =>
            <LogoRC RCDL={context} />
          }</RCDLStateContext.Consumer>
      </div>
      <LayoutContainer additionalClass="rc-max-width--xl" style={{height: '100%'}}>
        <Column align="middle" style={{
          height: '100%',
          paddingBottom: 0
        }} additionalClass="rc-md-up">
          <Image
            data={data.allContentfulAsset.edges[0].node}
            alt='Login image'
            style={{
              position: 'absolute',
              width: '100%',
              height: '80%',
              bottom: 0
            }}
            crop={'pad'}
          />
        </Column>
        <Column align="middle" additionalClass={`rc-text--center ${flexColumnMobile}`}>
          <h1 className="rc-alpha rc-padding-x--lg rc-paddign-bottom">Welcome to the Royal Canin Pet Owner Insights</h1>
          {
            !requestStatus.isSent && (
              <>
                <p className="rc-padding-x--xl">
                  Enter your Royal Canin email address and we&apos;ll send you a 30 day passcode.</p>
                <form onSubmit={handleSubmit}>
                  <LayoutContainer additionalClass="rc-padding-x--md">
                    <Column>
                      <TextField
                        onChange={handleChange}
                        additionalClass="rc-input--inline rc-input--label rc-full-width rc-text--left"
                        label="Your Royal Canin email address"
                        type="email"
                        value={values.email}/>
                    </Column>
                    <Column align="bottom" additionalClass="rc-text--left">
                      <Button additionalClass="rc-full-width">Request access</Button>
                    </Column>
                  </LayoutContainer>
                </form>
              </>
            )
          }
          {
            requestStatus.isSent && requestStatus.isEmailCorrect && (
              <>
                <p className="rc-padding-x--xl">
                  {`Thanks for requesting access You will receive a link at <${values.email}>.`}
                </p>
                <a className="rc-styled-link" href="#" onClick={handleSubmit}>Resend link</a>
              </>
            )
          }
          {
            requestStatus.isSent && !requestStatus.isEmailCorrect && (
              <>
                <p className="rc-padding-x--xl">Your email is not correct. Please pass valid Royal Canin email.</p>
                <Button handleClick={() => updateRequestStatus({})}>Go back</Button>
              </>
            )
          }
        </Column>
      </LayoutContainer>
    </div>
  );
};

export {Login};
