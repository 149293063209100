import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description Component for displaying one of the RCDL icons.
 * @param {string} icon Name of the wanted icon. Icons name taken from RCDL icons list.
 * @param {string} size Size of the icon, according to RCDL rules.
 * @param {string} additionalClass Any addtional classes.
 * @return {DOMElement} Icon React element.
 */
const Icon = ({icon, size = 'xs', additionalClass = ''}) => {
  const sizeModifier = size === 'xs' ? '--xs' : '';
  const iconClass = `rc-icon rc-${icon}${sizeModifier} rc-iconography${sizeModifier} ${additionalClass}`;

  return <span style={{display: 'inline-block'}} className={iconClass}></span>;
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
  additionalClass: PropTypes.string
};

export {Icon};
