import React from 'react';
import PropTypes from 'prop-types';

import {LayoutContainer, Column} from '../layouts';
import {Image} from '../common';
import {reverseColumnMobile} from '../../styles/block.module.css';

/**
 * @description One of the main content blocks component. Displays text and image vertically.
 * @param {string} title Block title.
 * @param {string} summary Summary text.
 * @param {string} containerClass Addtional container class.
 * @param {object} imageData Data object containg image information.
 * @param {DOMElement} children Any addtional, valid DOM Element.
 * @param {string} orderMobile Defines order on mobile devices.
 * @return {DOMElement} Hero React element.
 */
const Hero = ({title, summary, containerClass, imageData, children, orderMobile = 'reversed'}) => {

  const reverseOrderClass = orderMobile === 'reversed' ? reverseColumnMobile : '';

  return (
    <LayoutContainer
      columns="one"
      additionalClass={`${containerClass} rc-max-width--xl rc-text--center rc-padding-bottom--md rc-padding-top--md`}>
      <Column additionalClass={reverseOrderClass}>
        <div>
          <h1 className="rc-alpha rc-margin-top--md">{title}</h1>
          <p className="rc-max-width--md rc-padding-bottom--sm rc-padding-x--md">{summary}</p>
          {children}
        </div>
        <div className="rc-margin-top--sm rc-margin-bottom--lg--mobile">
          <Image data={imageData} style={{maxHeight: 450}}/>
        </div>
      </Column>
    </LayoutContainer>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  containerClass: PropTypes.string,
  children: PropTypes.node,
  imageData: PropTypes.object,
  orderMobile: PropTypes.oneOf(['reversed', 'regular'])
};

export {Hero};

