import React from 'react';
import PropTypes from 'prop-types';

import {InsightCard, Pagination} from '../common';
import {Column} from '../layouts';
import blockStyles from '../../styles/block.module.css';

/**
 * @description Displays grid of insigths cards.
 * @param {array} cards Array with cards data.
 * @param {number} currentPagination Current page number to display.
 * @param {function} setCurrentPagination Method to change current page state on component level higher.
 * @param {number} maxPagination Max number of pages for pagination.
 * @param {function} setMaxPagination Method to set the max number of pages for pagination.
 * @param {number} pageWidth Current viewport width.
 * @return {DOMElement} Insight Card React element.
 */
const InsightCardsGrid = ({
  cards,
  currentPagination = 1,
  setCurrentPagination,
  maxPagination,
  setMaxPagination,
  pageWidth,
  fromSwifttype
}) => {

  /**
   * @description Slices array into array of arrays.
   * @param {array} arr Array that needs to be sliced.
   * @param {number} size Maximum number of items in each array.
   * @return {array} Array of arrays with defined length.
   */
  const sliceArrayIntoGroups = (arr, size) => {
    // TODO: Move it to helper function.
    if (!arr) {
      return [];
    }
    let result = [];
    while (arr.length > 0) {
      result.push(arr.splice(0, size));
    }
    if (setMaxPagination) {
      setMaxPagination(result.length);
    }
    return result;
  };

  /**
   * @description Renders cards section that represents each pagination page.
   * @return {array} Array of card blocks.
   */
  const renderCardSections = () => {
    let rows = sliceArrayIntoGroups(cards, pageWidth > 768 ? 12 : 4);
    return rows
      .filter((item, index) => index + 1 === currentPagination)
      .map((row, index) => {
        return (
          <div key={index} className="rc-content-card rc-layout-container rc-match-heights rc-margin-bottom--sm">
            {renderCards(row)}
          </div>
        );
      });
  };

  /**
   * @description Renders insights cards.
   * @param {array} row Array with cards data.
   * @return {array} Array with card elements.
   */
  const renderCards = row => {
    return row.map(item => {
      return (
        <Column
          key={item.title.title}
          additionalClass={`${blockStyles.paddingXNoneMobile} ${blockStyles.columnOverwrite}`}
        >
          <InsightCard
            meta={item.taxonomies}
            url={item.fields.slug !== '/' ? '/' + item.fields.slug : '/'}
            ratings={item.ratings}
            fromSwifttype={fromSwifttype}
          >
            {item.title.title}
          </InsightCard>
        </Column>
      );
    });
  };

  if (!cards || cards.length === 0) {
    return null;
  }
  else {
    return (
      <div>
        {renderCardSections()}
        {maxPagination > 1 && (
          <>
            <Pagination
              currentPagination={currentPagination}
              setCurrentPagination={setCurrentPagination}
              maxPagination={maxPagination}
            />
            <div className="rc-margin-bottom--sm" />
          </>
        )}
      </div>
    );
  }
};

InsightCardsGrid.propTypes = {
  cards: PropTypes.array,
  currentPagination: PropTypes.number,
  setCurrentPagination: PropTypes.func,
  maxPagination: PropTypes.number,
  setMaxPagination: PropTypes.func,
  pageWidth: PropTypes.number
};

export {InsightCardsGrid};
