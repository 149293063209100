import React from 'react';
import PropTypes from 'prop-types';

import {RCDL_VERSION} from '../../config';

/**
 * @description Component to use as a placeholder on loading.
 * @param {object} positionStyle Style object to describe posotion.
 * @return {DOMElement} Loading Screen React element.
 */
const LoadingScreen = ({positionStyle}) => {

  const style = positionStyle ? positionStyle : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  };

  return (
    <div style={style}>
      <div className="rc-loader-infinite" data-js-progress="">
        <object
          className="rc-loader__logo logo-svg logo--crown"
          data={`https://d1a19ys8w1wkc1.cloudfront.net/logo--crown.svg?v=${RCDL_VERSION}`}
          type="image/svg+xml">
          <img
            src={`https://d1a19ys8w1wkc1.cloudfront.net/1x1.gif?v=${RCDL_VERSION}`}
            style={{backgroundImage: `url(https://d1a19ys8w1wkc1.cloudfront.net/logo--crown.png?v=${RCDL_VERSION})`}}
            width="150"
            height="100"
            alt="Royal Caninn logo" />
        </object>
        <div className="rc-loader__spinner"></div>
        <div className="rc-loader__background"></div>
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  positionStyle: PropTypes.object
};

export {LoadingScreen};
