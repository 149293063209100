import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description Divider component, adds visual divider that can be used to separate sections.
 * @param {string} size Defining size of the divider, using RCDL define spacers.
 * @param {string} colour Define colour of the divider, using RCDL brand colours.
 * @param {string} additionalClass Addtional class.
 * @return {DOMElement} Divider React element.
 */
const Divider = ({
  size = 'xs',
  colour = 'brand4',
  additionalClass = ''}) =>
  <div className={`rc-padding-bottom--${size} rc-bg-colour--${colour} ${additionalClass}`}></div>;

Divider.propTypes = {
  size: PropTypes.oneOf(['xs', 'md', 'lg', 'xl']),
  colour: PropTypes.oneOf(['brand4', 'interface']),
  additionalClass: PropTypes.string
};

export {Divider};
