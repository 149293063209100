import React from 'react';
import PropTypes from 'prop-types';

import {LabelButton} from '../common';
import logout from '../../helpers/logout';
import {LayoutContainer, Column} from '../layouts';
import {NavigationList} from '../common/NavigationList';
import typographyStyles from '../../styles/typography.module.css';
import listStyles from '../../styles/list.module.css';
import {useMegaMenuNavigation} from '../../static_queries';

/**
 * @description Displays main footet component.
 * @param {boolean} separate Defines if should create version of footer that's 'outside'
 * of the main website - no navigation and other internal links.
 * @return {DOMElement} Footer React element.
 */
const Footer = ({separate}) => {

  const {navigationList: navigationSections} = useMegaMenuNavigation()[0].node;

  /**
   * @description Render navigation columns.
   * @return {array} Array with navigation columns.
   */
  const renderColumns = () => {
    return navigationSections.map((section, i) => {
      return (
        <li key={`${section.id}-footer-${i}`} className='rc-list__item rc-list__item--group'>
          <NavigationList
            location="footer"
            section={section}
            extraClasses={{aClass: 'rc-list__link', liClass: 'rc-list__item'}}
          />
        </li>
      );
    });
  };

  return (
    <footer className="rc-bg-colour--interface-dark" style={{position: 'relative'}}>
      <div className="rc-max-width--xl rc-scroll--y">
        {!separate && (
          <>
            <LayoutContainer columns="four" additionalClass="rc-md-up">
              <Column additionalClass="rc-triple-width">
                <nav className="rc-menubar">

                  <ul className="rc-list rc-list--blank rc-list--inverse">
                    <li className="rc-list__item">
                      <LabelButton
                        additionalClass="rc-btn--inverse"
                        icon="user"
                        colour="brand3"
                        handleClick={logout}>Log out</LabelButton>
                    </li>
                  </ul>
                </nav>
              </Column>
              <Column additionalClass="rc-text--right">
                <LabelButton
                  additionalClass="rc-btn--inverse"
                  icon="up"
                  colour="brand3"
                  tag="link"
                  href="#"
                  external={true}>Back to top</LabelButton>
              </Column>
            </LayoutContainer>

            <div className="rc-divider rc-md-up" />

            <LayoutContainer
              additionalClass="rc-max-width--xl rc-content-h-middle"
              columns="five"
              style={{height: '100%'}}>
              <Column
                additionalClass="rc-padding-y--md--mobile rc-padding-y--md"
                customTag="nav" size="quad" data-toggle-group="mobile" data-toggle-effect="rc-expand--vertical">
                <nav className="rc-padding-x--none--mobile rc-full-width">
                  <ul className="rc-list rc-list--three-column rc-list--blank rc-list--align rc-list--inverse">
                    {renderColumns()}
                  </ul>
                </nav>
              </Column>
              <Column/>
            </LayoutContainer>

            <div className="rc-divider rc-md-up" />

            <LayoutContainer columns="one" additionalClass="rc-md-down">
              <Column additionalClass="rc-padding-x--none--mobile">
                <nav>
                  <ul className="rc-list rc-list--blank rc-list--align rc-list--inverse">
                    <li className="rc-list__item ">
                      <LabelButton
                        additionalClass={`rc-btn--inverse ${typographyStyles.regularSize}`}
                        icon="search"
                        colour="brand3">Search</LabelButton>
                    </li>
                    <li className="rc-list__item">
                      <LabelButton
                        additionalClass={`rc-btn--inverse rc-margin-bottom--md--mobile ${typographyStyles.regularSize}`}
                        icon="user"
                        colour="brand3"
                        handleClick={logout}>Log out</LabelButton>
                    </li>
                    <li className="rc-list__item">
                      <LabelButton
                        additionalClass="rc-btn--inverse"
                        icon="up"
                        colour="brand3"
                        tag="link"
                        href="#"
                        external={true}>Back to top</LabelButton>
                    </li>
                  </ul>
                </nav>
              </Column>
            </LayoutContainer>
            <div className="rc-divider rc-md-down rc-margin-bottom--lg" />
          </>
        )}
        <LayoutContainer columns="one">
          <Column additionalClass="rc-padding-x--none--mobile rc-padding-y--xl--desktop">
            <nav>
              <ul className={`rc-list rc-list--blank rc-list--inverse rc-list--align ${listStyles.listInlineDesktop}`}>
                <li
                  className="rc-list__item rc-text--inverse rc-self-h-middle rc-padding-left--md--mobile
                  rc-margin-bottom--md--mobile rc-padding-left--md--desktop rc-margin-right--lg
                  rc-margin-right--none--mobile">Contact Royal Canin</li>
                <li className="rc-list__item">
                  <LabelButton
                    tag="link"
                    external
                    additionalClass={`rc-btn--inverse ${typographyStyles.regularSize}`}
                    icon="email"
                    colour="brand3"
                    href="mailto:Daniela.Gallegos@royalcanin.com">Contact us</LabelButton>
                </li>
              </ul>
            </nav>
          </Column>
        </LayoutContainer>
      </div>
      <div className="rc-bg-colour--brand4">
        <LayoutContainer
          additionalClass="rc-max-width--xl rc-padding-x--xs--desktop rc-padding-x--none--mobile
            rc-flex-direction--reverse--sm-down">
          <Column additionalClass="rc-padding-x--none--mobile rc-padding-top--md--mobile">
            <ul className="rc-list rc-list--align rc-list--blank rc-list--inline rc-text--center--sm-down">
              <li className="rc-list__item rc-padding-right--lg">
                <a href="https://www.royalcanin.com/us" className="rc-list__link">© Royal Canin SAS 2019</a>
              </li>
              <li className="rc-list__item ">
                <a href="https://www.mars.com/global/policies/privacy/pp-english" className="rc-list__link">Privacy</a>
              </li>
            </ul>
          </Column>
        </LayoutContainer>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  separate: PropTypes.bool
};

export {Footer};
