import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * ClickListenerWrap allows the wrapping of elements to track
 * if a user is clicking within. If the user is, it sets the provided
 * function to either true or false.
 */
class ClickListenerWrapper extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    const {setFocusParam} = this.props;
    if (setFocusParam) {
      if (!this.node.contains(e.target)) {
        setFocusParam(false);
      }
      else {
        setFocusParam(true);
      }
    }
  }

  render() {
    const {children, style} = this.props;
    return (
      /* eslint no-return-assign: "error" */
      <div style={style} ref={node => (this.node = node)}>
        {children}
      </div>
    );
  }
}

ClickListenerWrapper.propTypes = {
  setFocusParam: PropTypes.func
};

export default ClickListenerWrapper;
