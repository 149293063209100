import React from 'react';
import PropTypes from 'prop-types';

import {Tooltip} from '.';
import typographyStyles from '../../styles/typography.module.css';
import blockStyles from '../../styles/block.module.css';
import journeyStagesStyles from '../../styles/journeyStages.module.css';

const OwnerRoleTooltip = ({data}) => {

  const titleClass = `rc-espilon ${typographyStyles.brand3} ${typographyStyles.uppercase}`;

  return (
    <Tooltip
      theme="brand2"
      triggerClass={blockStyles.displayInlineFlex}
      button={
        <p className={`rc-margin--none ${blockStyles.cursorPointer}`}>Owner Role: <b className="rc-text-colour--brand1">{data.title}</b></p>
      }>
      <div className="rc-padding--xs">
        <img
          src={data.icon.file.url}
          alt={data.title}
          className={journeyStagesStyles.ownerIcon}/>
        <span className={titleClass}><b>{data.title}</b></span>
        <p className="rc-margin-y--xs">{data.summary.summary}</p>
      </div>
    </Tooltip>
  );
};

OwnerRoleTooltip.propTypes = {
  data: PropTypes.object
};

export {OwnerRoleTooltip};
