import React, {useEffect} from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

import {RCDL_VERSION} from '../../config';

/**
 * @description Component that displays Royal Canin logo.
 * @param {object|array} RCDLState Object that describes load state of RCDL assets.
 * @return {DOMElement} Logo React element.
 */
const LogoRC = ({RCDLState}) => {
  useEffect(() => {
    if (typeof RCDLState !== 'undefined') {
      if (RCDLState[0].RCDLloaded) {
        RCDL.utilities.svgAnimation.init('[data-js-import-interactive-svg]');
      }
    }
  }, [RCDLState]);


  return (
    <Link to="/" className="rc-header__brand">
      <span className="rc-screen-reader-text">Royal Canin Logo</span>
      <object
        id="header__logo"
        className="rc-header__logo"
        type="image/svg+xml"
        data-js-import-interactive-svg
        data={`https://d1a19ys8w1wkc1.cloudfront.net/logo--animated.svg?v=${RCDL_VERSION}`}>
        <img
          alt="Royal Canin logo"
          height="100"
          src={`https://d1a19ys8w1wkc1.cloudfront.net/logo--animated.svg?v=${RCDL_VERSION}`}
          width="135" />
      </object>
    </Link>
  );
};

LogoRC.propTypes = {
  RCDLState: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

export {LogoRC};
