import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {TextContentBlock, Image, Button, Select} from '../common';
import {LayoutContainer, Column} from '../layouts';
import blockStyles from '../../styles/block.module.css';

/**
 * @description Specific layout container with downloading different types of assets funcionality.
 * @param {object} image Data object containg image information.
 * @param {string} title Block title.
 * @param {object} options Object containg options for select component.
 * @return {DOMElement} Horizontal Content block React element.
 */
const AssetsDownloadBlock = ({
  image,
  title,
  options
}) => {

  // All options that are passed to the Select component, proccessed from
  // bare object passed from the parent.
  const [selectOptions, setSelectOptions] = useState(null);
  // Current selected option in Select component. Kept as a state in parent
  // component so we can pass it to different components.
  const [selectedType, setSelectedType] = useState(null);

  /**
   * @description Prepare passed options, to have correct structure for Select components.
   * Also adds first disabled label.
   * @return {array} Array of objects with correct structured options for Select component.
   */
  const prepareOptions = () => {
    let processedOptions = options.map(option => {
      return {
        value: option.type,
        url: `https:${option.zipFile.file.url}`,
        label: option.type,
        id: option.id
      };
    });

    processedOptions = [
      ... {
        value: '',
        url: '',
        label: 'File type',
        id: 'file_type',
        attributes: {
          disabled: true
        }
      },
      ...processedOptions
    ];
    return processedOptions;
  };

  /**
   * @description Handles select input change.
   * @param {object} e Syntethic event passed from Select component;
   */
  const handleSelectChange = (e) => {
    setSelectedType(e.target.value);
  };

  /**
   * @description Handles click event on Button.
   * @param {string} type Type of currently selected file type.
   */
  const handleClick = (type) => {
    const downloadUrl = selectOptions.filter(option => {
      return option.value === type;
    });

    if (downloadUrl) {
      window.open(downloadUrl[0].url, '_blank');
    }
    else {
      throw new Error('Something is wrong. Please try again.');
    }
  };

  useEffect(() => {
    setSelectOptions(prepareOptions());
  });

  if (selectOptions) {
    return (
      <LayoutContainer
        additionalClass="rc-max-width--lg rc-padding-bottom--xl rc-reverse-layout-mobile">
        <Column style={{zIndex: 1}}>
          {image && <Image data={image}/>}
        </Column>
        <Column align='middle' additionalClass={`rc-margin-bottom--md--mobile ${blockStyles.flexColumnMobile}`}>
          <TextContentBlock
            title={title}
            wrapperClass="rc-margin-bottom--md"/>
          <LayoutContainer>
            <Column>
              <Select options={selectOptions} label="Select file type" onChange={handleSelectChange}/>
            </Column>
            <Column>
              <Button onClick={() => handleClick(selectedType)} disabled={!selectedType}>Download files</Button>
            </Column>
          </LayoutContainer>
        </Column>
      </LayoutContainer>
    );
  }
  else {
    return null;
  }
};

AssetsDownloadBlock.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  options: PropTypes.array
};

export {AssetsDownloadBlock};

