import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {truncate} from 'lodash';
import {useInsights} from '../../static_queries';

/**
 * @description Displays single random insight snippet and link to its page.
 * @param {function} handleClick Methods invoke when link is clicked.
 * @return {DOMElement} InsightSnippet React element.
 */
const InsightSnippet = ({handleClick}) => {

  const getRandom = (arr) => arr[Math.floor((Math.random() * arr.length))];
  const insight = getRandom(useInsights()).node;

  return (
    <div className="rc-padding-x--md">
      <h1 className="rc-gamma">
        {truncate(insight.title.title, {
          length: 130,
          separator: ' '
        })}
      </h1>
      <Link to={insight.fields.slug} className="rc-styled-link" onClick={()=>handleClick(false)}>See full insight</Link>
    </div>
  );
};

InsightSnippet.propTypes = {
  handleClick: PropTypes.func
};

export {InsightSnippet};

