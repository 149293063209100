import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {TextField, Button} from '../common';
import useForm from '../../helpers/useForm';
import formStyles from '../../styles/forms.module.css';
import sendContactFormMessage from '../../helpers/sendContactFormMessage';

/**
 * @description Contact form component. Handle inputs displaying and submit logic.
 * @param {object|array} RCDLState RCDLState Object that describes load state of RCDL assets.
 * @param {string} pageTitle Title of current page. Used for contact form message.
 * @return {DOMElement} Contact form React element.
 */
const ContactForm = ({RCDLState, pageTitle}) => {

  let [formSubmitted, setIsFormSubmitted] = useState();
  let {values, handleChange, handleSubmit} = useForm(handleLogin);

  /**
   * @description Handles login funcionality, passed to form submit event.
   */
  function handleLogin() {
    sendContactFormMessage(values, window.location.href, pageTitle).then(data => {
      setIsFormSubmitted(data.data.data.formSubmitted);
      handleChange(null, true); // Cleanup form
    });
  }

  useEffect(() => {
    if (typeof RCDLState !== 'undefined') {
      if (RCDLState[0].RCDLloaded) {
        RCDL.features.FormElements.formValidation('[data-js-validate]', null, null);
      }
    }
  }, [RCDLState]);

  return (
    <>
      {formSubmitted && (
        <aside className="rc-alert rc-alert--success rc-margin-bottom--xs" role="alert">
          <span>Thank you for the email. Someone from our team will be in touch with you shortly.</span>
        </aside>
      )}
      <form onSubmit={handleSubmit} className={formStyles.formElement}>
        <TextField
          label="Your name"
          additionalClass="rc-input--label rc-margin-bottom--sm--mobile rc-input--full-width"
          name="userName"
          value={values.userName || ''}
          onChange={handleChange}
          id="user-name"/>
        <TextField
          label="Your email address"
          type="email"
          additionalClass="rc-input--label rc-margin-bottom--lg--mobile rc-margin-bottom--sm rc-input--full-width"
          name="userEmail"
          value={values.userEmail || ''}
          onChange={handleChange}
          id="user-email"
          data-js-validate=""
          data-js-warning-message="Please enter a valid email"/>
        <TextField
          label="Your enquiry"
          additionalClass="rc-margin-bottom--lg--mobile rc-margin-bottom--xs rc-input--full-width"
          type="textarea"
          name="userEnquiry"
          value={values.userEnquiry || ''}
          onChange={handleChange}
          id="user-enquiry"/>
        <Button type="submit">Submit</Button>
      </form>
    </>
  );
};

ContactForm.propTypes = {
  RCDLState: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  pageTitle: PropTypes.string
};

export {ContactForm};
