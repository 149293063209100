import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

import {LabelButton} from '../common';
import typographyStyles from '../../styles/typography.module.css';

/**
 * @description Component for displaying single navigation list with section header.
 * @param {string} extraClasses Addtional classes.
 * @param {string} location Defines location of list, either header or footer.
 * @param {object} section Object with single list data - labels, urls etc.
 * @param {function} toggleModal Function to handle opening/closing of navigation modal.
 * @param {number} pageWidth Current viewport width.
 * @return {DOMElement} Navigation List React element.
 */
const NavigationList = ({extraClasses, location = 'header', section, toggleModal, pageWidth}) => {

  if (!section || section.length === 0) {
    return null;
  }

  const {navigationHeader, navigationItem} = section;
  const toggleID = navigationItem !== null ? `${section.id}-${location}` : null;
  let headingClass = `rc-list__header ${location === 'footer' ?
    `rc-padding-left--md--mobile ${typographyStyles.visitedWhite}` :
    ''}`;
  headingClass = `${headingClass} ${!toggleID ? typographyStyles.noPseudo : ''}`;

  /**
   * @description Handles if toggle should closed on specified event.
   */
  const handleToggleModal = () => {
    if (location === 'header') {
      toggleModal(false);
    }
    else {
      return;
    }
  };

  /**
   * @description Handles behaviour on header click. Depends on page width,
   * some of the headers should close modal.
   * @param {string} toggleID Heading element ID.
   */
  const handleHeaderClick = (toggleID) => {
    if (pageWidth <= RCDL.config.breakpoints['xl'] && !toggleID) {
      handleToggleModal();
    }
    else if (pageWidth >= RCDL.config.breakpoints['xl']) {
      handleToggleModal();
    }
    else {
      return;
    }
  };

  /**
   * @description Renders heading element.
   * @return {DOMElement} Header link element.
   */
  const renderHeader = () => {
    if (navigationHeader) {
      return (
        <Link
          data-toggle={toggleID}
          to={`/${navigationHeader[0].fields.slug}`}
          onClick={() => handleHeaderClick(toggleID)}
          className={headingClass}
          role="menuitem">{navigationHeader[0].navigationLabel || navigationHeader[0].title}</Link>
      );
    }
  };

  /**
   * @description Renders list items.
   * @return {array} Array with list item elements.
   */
  const renderItems = () => {
    if (navigationItem !== null) {
      return (
        <>
          {
            location !== 'footer' && (
              <>
                <li className="rc-list__item rc-md-down">
                  <LabelButton
                    icon="left"
                    data-toggle={toggleID}
                    customLabelButton
                    additionalClass="rc-list__link">Back</LabelButton>
                </li>
                <li className="rc-list__item rc-md-down">
                  <Link
                    to={`/${navigationHeader[0].fields.slug}`}
                    onClick={() => handleToggleModal(false)}
                    className="rc-list__header"
                    role="menuitem">
                    {navigationHeader[0].navigationLabel || navigationHeader[0].title}</Link>
                </li>
              </>
            )
          }
          {
            navigationItem.map(item => {
              return (
                <li key={item.id} className={extraClasses.liClass} onClick={() => handleToggleModal(false)}>
                  <Link
                    className={extraClasses.aClass}
                    to={`/${item.fields.slug}`}>
                    {item.navigationLabel || item.title}</Link>
                </li>
              );
            })
          }
        </>
      );
    }
  };

  return (
    <>
      {renderHeader()}
      <ul
        className="rc-list rc-list--blank rc-list--align"
        id={toggleID}
        aria-labelledby="nav-header-cat"
        role="menu">
        {renderItems()}
      </ul>
    </>
  );
};

NavigationList.propTypes = {
  extraClasses: PropTypes.object,
  section: PropTypes.object,
  location: PropTypes.oneOf(['header', 'footer']),
  toggleModal: PropTypes.func,
  pageWidth: PropTypes.number
};

export {NavigationList};
