import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

/**
 * @description Single breadcrumb used usally as a back button.
 * @param {string} link URL for target page.
 * @param {string} label Label to display in breadcrumb.
 * @return {DOMElement} SingleBreadcrumb React element.
 */
const SingleBreadcrumb = ({link, label}) => (
  <div className="rc-progress rc-progress--breadcrumbs-single">
    <Link to={link} className="rc-progress__breadcrumb rc-styled-link">{label}</Link>
  </div>
);

SingleBreadcrumb.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string
};

export {SingleBreadcrumb};
