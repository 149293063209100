import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {LabelButton} from '../common';
import modifyClass from '../../helpers/modifyClass';
import modalStyles from '../../styles/modal.module.css';

/**
 * @description Modal component for displaying mega menu.
 * @param {boolean} full Defines if modal should have full size.
 * @param {DOMElement} children Any valid DOM element.
 * @param {boolean} isOpen Defines if modal is open or not.
 * @param {function} toggleModal Method to control modal opne/close state.
 * @param {string} headerClass Additional header class.
 * @param {object|array} RCDLState Object that describes load state of RCDL assets.
 * @return {DOMElement} Modal React element.
 */

const Modal = ({full = false, children, isOpen, toggleModal, headerClass, RCDLState}) => {
  let modalClass = `rc-modal rc-content--fixed-header ${modalStyles.modal} ${full ? 'rc-modal--full' : ''}`;
  modalClass = !isOpen ? `${modalClass} rc-hidden` : modalClass;

  useEffect(() => {
    let action = isOpen ? 'add' : 'remove';
    if (typeof RCDL.features.ToggleGroup !== 'undefined') {
      modifyClass(action, document.body, 'rc-scroll--none');
      RCDL.features.ToggleGroup.init('[data-toggle-group]');
    }
  }, [RCDLState]);

  return (
    <div>
      <aside role="modal" className={modalClass}>
        <div className="rc-modal__container">
          <header className={`rc-modal__header ${headerClass}`}>
            <LabelButton icon="close" label={false} handleClick={() => toggleModal(false)}>Close modal</LabelButton>
          </header>
          <section className="rc-modal__content rc-scroll--y">
            {children}
          </section>
        </div>
      </aside>
      {!full && isOpen && (<div className="rc-shade"></div>)}
    </div>
  );
};

Modal.propTypes = {
  full: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  headerClass: PropTypes.string,
  RCDLState: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

export {Modal};
