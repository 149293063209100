import React, {createContext, useContext, useEffect, useReducer, useState} from 'react';

export const RCDLStateContext = createContext();

export const useRCDLStateValue = () => useContext(RCDLStateContext);


export const rcdlReducer = (state, action) => {
  // Currently the reducer isn't used.
  return state;
};

let rcdlInitialState = {
  pageHasChanged: 0,
  RCDLloaded: false
};


export const RCDLStateProvider = (
  {
    reducer,
    initialState,
    children
  }) => {

  const [RCDLloaded, setRCDL] = useState(false);
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(window.location.origin);

  rcdlInitialState.pageHasChanged = index;
  rcdlInitialState.RCDLloaded = RCDLloaded;
  rcdlInitialState.lastPage = page;
  rcdlInitialState.triggerPageCount = (current) => setIndex(current + 1);
  rcdlInitialState.setPage = (url) => setPage(url);

  useEffect(
    () => {
      if (typeof RCDL !== 'undefined') {
        setRCDL(true);
      }
    }, [RCDL]);

  return (
    <RCDLStateContext.Provider value={useReducer(rcdlReducer, rcdlInitialState)}>
      {children}
    </RCDLStateContext.Provider>);
};
