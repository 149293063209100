import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import {TextContentBlock, Image} from '../common';

/**
 * @description One of the basic content blocks. Displays text and image in vertical direction,
 * usually wrapped in single column.
 * @param {object} image Data object with image information.
 * @param {string} title Block title.
 * @param {string} summary Block summary text.
 * @param {array} buttonsConfig Array of objects with button configuration.
 * @return {DOMElement} VerticalContentBlock React element.
 */
const VerticalContentBlock = (
  {
    image,
    title,
    summary,
    buttonsConfig
  }) => {

  const summaryText = summary.cardSummary ? summary.cardSummary : summary.summary;

  return (
    <div className="rc-max-width--md">
      <Link to={buttonsConfig[0].slug} >
        <Image data={image} />
      </Link>
      <div className='rc-padding-top--md rc-padding-bottom--md'>
        <TextContentBlock
          style={{maxWidth: '85%'}}
          title={title}
          text={summaryText}
          buttonsConfig={buttonsConfig} />
      </div>
    </div>
  );
};

VerticalContentBlock.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.object,
  buttonsConfig: PropTypes.array
};

export {VerticalContentBlock};

