import axios from 'axios';

/**
 * @description Sends post request to endpoint that handles sending email with login url.
 * @param {string} email Email addres of user applying for the login.
 * @param {string} url URL of the page.
 */
async function sendUrlRequest(email, url) {
  const connection = axios({
    method: 'post',
    baseURL: `${process.env.GATSBY_SLS_URL}/handleLoginRequest`,
    data: {
      email,
      url
    }
  });
  return connection
    .then(() => {
      return {
        emailCorrect: true
      };
    })
    .catch(error => {
      return {
        error,
        emailCorrect: false
      };
    });
}

export default sendUrlRequest;
