import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Tooltip, Modal, MegaMenu, SearchModal, TopNavigation} from '../organisms';
import {LabelButton, LogoRC} from '../common';
import logout from '../../helpers/logout';
import {RCDLStateContext} from '../../contexts/RCDLcontext';
import {TooltipsContextProvider} from '../../contexts/tooltipsContext';

/**
 * @description Displays main header with navigation and mega menu.
 * @param {boolean} separate Defines if should create version of header that's 'outside'
 * of the main website - no main navigation and other internal links.
 * @return {DOMElement} Header React element.
 */
const Header = ({separate}) => {
  let [modalIsOpen, toggleModal] = useState(false);
  let [searchModalOpen, toggleSearchModal] = useState(false);

  return (
    <TooltipsContextProvider>
      <RCDLStateContext.Consumer>{(context) => {
        return (
          <>
            {/* Custom z-index overwrite to ensure that owner journey tooltips are not overlapping header on scroll. */}
            <header className="rc-header" style={{zIndex: 99}}>
              <nav className="rc-header__nav rc-header__nav--primary">
                <ul className="rc-list rc-list--blank rc-list--inline rc-list--align" role="menubar">
                  {!separate && (
                    <li className="rc-list__item">
                      <LabelButton
                        icon="menu"
                        additionalClass="rc-md-up"
                        handleClick={() => toggleModal(!modalIsOpen)}>Menu</LabelButton>
                      <LabelButton
                        icon="menu"
                        label={false}
                        handleClick={() => toggleModal(!modalIsOpen)}
                        additionalClass="rc-md-down">Menu</LabelButton>
                    </li>
                  )}
                </ul>
                <LogoRC RCDLState={context}/>
                <ul className="rc-list rc-list--blank rc-list--inline rc-list--align rc-header__right" role="menubar">
                  {!separate && (
                    <>
                      <li className="rc-list__item rc-margin-right--none">
                        <Tooltip
                          id="logout-tooltip"
                          button={<LabelButton icon="user" label={false}>Log out</LabelButton>}>
                          <a className="rc-styled-link" href="#" onClick={logout}>Log out</a>
                        </Tooltip>
                      </li>
                      <li className="rc-list__item rc-margin-right--none">
                        <LabelButton
                          icon="search"
                          label={false}
                          handleClick={() => toggleSearchModal(!searchModalOpen)}>Search</LabelButton>
                      </li>
                    </>
                  )}
                </ul>
                <SearchModal
                  full
                  isOpen={searchModalOpen}
                  toggleModal={toggleSearchModal}
                  RCDLState={context}
                  headerClass="rc-md-up"/>
              </nav>
              <TopNavigation separate={separate}/>
            </header>
            {!separate && (
              <Modal full isOpen={modalIsOpen} toggleModal={toggleModal} RCDLState={context} headerClass="rc-md-up">
                <MegaMenu toggleModal={toggleModal}/>
              </Modal>
            )}
          </>
        );
      }}
      </RCDLStateContext.Consumer>
    </TooltipsContextProvider>

  );
};

Header.propTypes = {
  separate: PropTypes.bool
};

export {Header};

