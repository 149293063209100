import React from 'react';
import PropTypes from 'prop-types';
import {Rating} from '../common';
import {iconography} from '../../styles/typography.module.css';

/**
 * @description Wraper for displaying rating title and graphic representation.
 * @param {string} title Rating title.
 * @param {number} rate Rating number.
 * @return {DOMElement} Title Rating React element.
 */
const TitleRating = ({title, rate}) => {
  return (
    <>
      <div>
        <span className={`rc-delta rc-margin-right--xs ${iconography}`}>{title}:</span>
      </div>
      <div>
        <Rating rate={rate}/>
      </div>
    </>
  );
};

TitleRating.propTypes = {
  title: PropTypes.string,
  rate: PropTypes.number
};

export {TitleRating};
