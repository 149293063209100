import React, {useRef} from 'react';
import PropTypes from 'prop-types';

/**
 * @description Component that displays RCDL pagaintion.
 * @param {number} currentPagination Current page number to display.
 * @param {function} setCurrentPagination Method to change current page state on component level higher.
 * @param {number} maxPagination Max number of pages for pagination.
 * @return {DOMElement} Pagination React element.
 */
const Pagination = ({currentPagination, setCurrentPagination, maxPagination}) => {
  const input = useRef(null);
  const handlePaginationChange = (e, value) => {
    e.preventDefault();
    setCurrentPagination(value);
  };
  const disabledStyle = {
    opacity: 0.3,
    cursor: 'not-allowed'
  };

  /**
   * @description Handles what should happend when pagination input value is changed.
   * @param {object} e Object with data coming from React syntethic event.
   * @param {string} value Pagination input value.
   */
  const handleInputChange = (e, value) => {
    if (isNaN(value) || value > maxPagination) {
      return;
    }
    else {
      handlePaginationChange(e, value);
    }
  };

  return (
    <nav className="rc-pagination">
      <form onSubmit={(e) => handlePaginationChange(e, parseInt(input.current.value))} className="rc-pagination__form">
        <button
          disabled={currentPagination === 1}
          style={currentPagination === 1 ? disabledStyle : {}}
          className="rc-btn rc-pagination__direction rc-pagination__direction--prev rc-icon rc-left--xs rc-iconography"
          aria-label="Previous step"
          onClick={(e) => handlePaginationChange(e, parseInt(input.current.value) - 1)}></button>
        <div className="rc-pagination__steps">
          <input
            ref={input}
            type="text"
            className="rc-pagination__step rc-pagination__step--current"
            value={currentPagination || ''}
            onSubmit={(e) => handlePaginationChange(e, parseInt(input.current.value))}
            onChange={(e) => handleInputChange(e, parseInt(input.current.value))}
            aria-label="Current step" />
          <div className="rc-pagination__step rc-pagination__step--of">of
            <span data-total-steps-label=""> {maxPagination}</span>
          </div>
        </div>
        <button
          disabled={currentPagination === maxPagination}
          style={currentPagination === maxPagination ? disabledStyle : {}}
          className="rc-btn rc-pagination__direction rc-pagination__direction--prev rc-icon rc-right--xs rc-iconography"
          aria-label="Next step"
          onClick={(e) => handlePaginationChange(e, parseInt(input.current.value) + 1)}></button>
      </form>
    </nav>
  );
};

Pagination.propTypes = {
  currentPagination: PropTypes.number,
  setCurrentPagination: PropTypes.func,
  maxPagination: PropTypes.number
};

export {Pagination};
