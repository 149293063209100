import React from 'react';
import PropTypes from 'prop-types';

const RadioButtons = ({values, selected, handleChange}) => {

  const renderButtons = () => {
    return values.map((value, i) => {
      return (
        <div className="rc-input rc-input--inline" key={value.value + i} onClick={() => handleChange(value.value)}>
          <input
            className="rc-input__radio"
            id={value.value}
            value={value.value}
            type="radio"
            name="radio-button"
            onChange={(e) => handleChange(e.target.value)}
            checked={selected === value.value}/>
          <label className="rc-input__label--inline" htmlFor={value.id}>{value.label}</label>
        </div>
      );
    });
  };

  return (
    <>
      {renderButtons()}
    </>
  );
};

RadioButtons.propTypes = {
  values: PropTypes.array,
  selected: PropTypes.string,
  handleChange: PropTypes.func
};

export {RadioButtons};
