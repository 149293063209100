import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import {Rating} from '.';
import calculateRating from '../../helpers/calculateRating';
import typographyStyles from '../../styles/typography.module.css';
import setTitleCase from '../../helpers/setTitleCase';

/**
 * @description Card component for displaying short info about single Insight.
 * @param {DOMElement} children Any valid children.
 * @param {array} meta Array of meta tags to display on the card.
 * @param {string} url URL to single insight description page.
 * @param {array} ratings Array of ratings for different properties.
 * @return {DOMElement} InsightCard React element.
 */
const InsightCard = ({children, meta = [], url = '/', ratings, fromSwifttype}) => {

  /**
   * @description Render string that contains all meta tags.
   * @return {string} String with meta tags.
   */
  const renderMeta = () => {
    if (fromSwifttype) {
      return meta.join(', ');
    }
    else {
      return meta.map(el => el.title || el.speciesName || el.lifestageName).join(', ');
    }
  };

  const styles = {
    cardBody: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%'
    }
  };

  return (
    <Link to={url} className="rc-card__link rc-full-width">
      <article className="rc-card rc-card--a">
        <div className="rc-card__body rc-text--left" style={styles.cardBody}>
          <header>
            <p className="rc-card__meta rc-padding-bottom--xs">{meta.length > 0 && setTitleCase(renderMeta())}</p>
            <span className={`rc-card__title ${typographyStyles.breakWord}`}>{children}</span>
          </header>
          <div>{<Rating rate={Array.isArray(ratings) ? calculateRating(ratings) : ratings} max={5} />}</div>
        </div>
      </article>
    </Link>
  );
};

InsightCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  meta: PropTypes.array,
  url: PropTypes.string,
  ratings: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  fromSwifttype: PropTypes.bool
};

export {InsightCard};
