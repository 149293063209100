import {useStaticQuery, graphql} from 'gatsby';

/**
 * @description Custom hook. Query stages from gatsby nodes.
 * @return {object} Object with stages data.
 */
const useStages = () => {
  const {allContentfulStage} = useStaticQuery(graphql`
    query {
      allContentfulStage(
        sort: {
          fields: [stageNumber],
          order: ASC
        })  {
      edges {
        node {
          stageNumber
          id
          title
          summary {
            summary
          }
          taxonomies {
            __typename
            ... on ContentfulSpecies {
              speciesName
              contentful_id
            }
            ... on ContentfulLifestage {
              lifestageName
              contentful_id
            }
          }
          cardTitle
          cardSummary {
            cardSummary
          }
          fields {
            slug
          }
          cardImage {
            fluid: fluid(maxWidth: 800, maxHeight: 600) {
              base64
              
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            fixed(width: 800, height: 600) {
              src
              width
              height
            }
          }
        }
      }
    }
    }
  `);
  return allContentfulStage.edges;
};

export {useStages};
