import React from 'react';
import PropTypes from 'prop-types';

import {InsightCardsGrid} from '../organisms';
import {useInsights} from '../../static_queries';

/**
 * @description Layout to wrap only few specific (related to current insight) insights.
 * @param {function} filterFunction Methods defining which insights should be showed.
 * @return {DOMElement} RelatedInsights React element.
 */
const RelatedInsights = ({filterFunction}) => {

  const insights = useInsights()
    .filter(filterFunction)
    .map(({node}) => node)
    .slice(0, 4);

  return (
    <>
      <h4 className="rc-gamma rc-margin-bottom--md">Related</h4>
      <InsightCardsGrid cards={insights} />
    </>
  );
};

RelatedInsights.propTypes = {
  filterFunction: PropTypes.function
};

export {RelatedInsights};
