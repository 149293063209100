/**
 * @description Get token either from local storage or url parameter.
 * If there's no token in local storage, it saves it into local storage.
 * @return {object} Object with browser information - name and version number.
 */
function getToken() {
  let tokenData = {};
  const TOKENKEY = 'insights_canvas_token';
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(TOKENKEY)) {
      tokenData = {
        token: localStorage.getItem(TOKENKEY),
        tokenFromStorage: true
      };
    }
    else {
      tokenData = {
        token: location.search.split('token=')[1],
        tokenFromStorage: false
      };
    }
  }
  if (tokenData.token && !tokenData.tokenFromStorage) {
    localStorage.setItem(TOKENKEY, tokenData.token);
  }
  return tokenData;
}

export default getToken;
