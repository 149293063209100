import {useStaticQuery, graphql} from 'gatsby';

/**
 * @description Custom hook. Query insights from gatsby nodes.
 * @return {object} Object with insights data.
 */
const useInsights = () => {
  const {allContentfulInsight} = useStaticQuery(graphql`
    query {
      allContentfulInsight {
        edges {
          node {
            fields {
              slug
              template
              speciesName
            }
            title {
              id
              title
            }
            id
            taxonomies {
              __typename
              ... on ContentfulSpecies {
                speciesName
                contentful_id
              }
              ... on ContentfulStage {
                title
                contentful_id
              }
              ... on ContentfulLifestage {
                lifestageName
                contentful_id
              }
            }
            ratings {
              ratingValue
            }
          }
        }
      }
    }
  `);
  return allContentfulInsight.edges;
};

export {useInsights};
