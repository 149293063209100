import React from 'react';
import PropTypes from 'prop-types';

import {TitleRating} from '.';
import ratingStyles from '../../styles/rating.module.css';

/**
 * @description Displays the list of insights rating.
 * @param {array} ratings Array of objects with ratings data.
 * @param {boolean} noRatings True if not ratings data supplied by parent component.
 * @return {DOMElement} List Title Rating React element.
 */
const ListTitleRating = ({overallRating, noRatings = false}) => {

  return (
    <div className="rc-margin-bottom--xl--mobile rc-padding-bottom--xl--mobile rc-margin-bottom--xl">
      <ul className={`rc-list rc-list--inline rc-list--align ${ratingStyles.ratingList} ${noRatings ? ratingStyles.overlay : ''}`}>
        <li
          className={`rc-list__item rc-margin-bottom--md--mobile ${ratingStyles.ratingTitleWrapper}`}>
          <TitleRating rate={overallRating} title='Overall Resonance'/>
        </li>
      </ul>
      {noRatings && (
        <div className="rc-text--center rc-margin-top--sm">
          {/* TODO: Need to add config object to store placeholder messages. We need to figure out how to set
          a default value for these. There's a Contentful plugin that we could probably use for that. */}
          <p className="rc-margin-bottom--none"><b>Quantifications coming soon</b></p>
        </div>
      )}
    </div>
  );
};

ListTitleRating.propTypes = {
  overallRating: PropTypes.number,
  noRatings: PropTypes.bool
};

export {ListTitleRating};
