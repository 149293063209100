import {useStaticQuery, graphql} from 'gatsby';

/**
 * @description Custom hook. Query main navigation from gatsby nodes.
 * @return {object} Object with main navigation data.
 */
const useMainNavigation = () => {
  const {allContentfulNavigation} = useStaticQuery(graphql`
    query {
      allContentfulNavigation(filter: {
        title: {
          eq: "Main navigation"
        }
      }) {
        edges {
          node {
            title
            navigationList {
              ... on ContentfulNavigationList {
                title
                navigationItem {
                  __typename
                  ... on ContentfulPage {
                    title
                    id
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                  ... on ContentfulSpecies {
                    title
                    id
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return allContentfulNavigation.edges;
};

export {useMainNavigation};
