import axios from 'axios';

/**
 * @description Sends post request to endpoint that handles sending email with contact form enquiry.
 * @param {object} values Values gathered from contact form submission.
 * @param {string} url URL of the page, on which contact form was submitted.
 * @param {title} title Title of the page, on which contact form was submitted.
 */
async function sendContactForm(values, url, title) {
  const connection = axios({
    method: 'post',
    baseURL: `${process.env.GATSBY_SLS_URL}/handleContactForm`,
    data: {
      values,
      url,
      title
    }
  });
  return connection
    .then((data) => {
      return {
        data
      };
    })
    .catch(error => {
      return {
        error
      };
    });
}

export default sendContactForm;
