import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-scroll';

import positionNavStyles from '../../styles/positionNav.module.css';
import createSlug from '../../helpers/createSlug';

/**
 * @description Displays side, fixed navigation with jump links.
 * @param {array} sections Array of objects with sections data.
 * @param {string} currentStage Name of current stage.
 * @param {string} position One of the component postion options.
 * @param {number} bottomOffset Number in pixels to define offset from the bottom of viewport.
 * @param {number} scrollOffset Number to define position of linked section after scroll-to link click.
 * @return {DOMElement} PositionNav React element.
 */
const PositionNav = ({sections, currentStage, position, bottomOffset, scrollOffset}) => {

  const styles = {
    listBottom: {
      bottom: bottomOffset
    }
  };

  /**
   * @description Render list items.
   * @return {array} Array of list items.
   */
  const renderItems = () => {
    return sections.map((node, i) => {
      let currentClass = positionNavStyles.listItem;
      let titleSlug = createSlug(node.title);
      if (currentStage === titleSlug) {
        currentClass = `${currentClass} ${positionNavStyles.listItemActive}`;
      }
      else {
        currentClass = `${currentClass} ${positionNavStyles.listItemInactive}`;
      }
      return (
        <li key={i}
          className={`${positionNavStyles.listItem} ${currentClass} rc-meta`}>
          <Link
            to={titleSlug}
            smooth
            offset={-(scrollOffset + 10)}
            duration={300}>
            {node.title}
          </Link>
        </li>
      );
    });
  };

  return (
    <ul className={`${positionNavStyles.list}`} style={position === 'bottom' ? styles.listBottom : ''}>
      {renderItems()}
    </ul>
  );
};

PositionNav.propTypes = {
  sections: PropTypes.array,
  currentStage: PropTypes.string,
  position: PropTypes.oneOf(['center', 'bottom']),
  bottomOffset: PropTypes.number,
  handleClick: PropTypes.func,
  scrollOffset: PropTypes.number
};

export {PositionNav};
