import axios from 'axios';

/**
 * @description Makes post request to API to checks if token is stored on S3, therefore valid to login.
 * @param {string} token Token used by user to login.
 * @return {boolean} Either token is valid or not.
 */
async function checkLogin(token) {
  let response = false;
  const connection = axios({
    method: 'post',
    baseURL: `${process.env.GATSBY_SLS_URL}/handleLogin`,
    data: {
      token
    }
  });
  try {
    response = await connection;
  }
  catch (err) {
    return err;
  }
  return response.data.isLogged;
}

export default checkLogin;
