import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import {LayoutContainer, Column} from '../layouts';
import {InsightCardsGrid} from '../organisms';
import createSlug from '../../helpers/createSlug';

// https://github.com/elastic/search-ui/blob/master/ADVANCED.md#result

/**
 * @description Displays search results - insights grid and other pages.
 * @return {DOMElement} SearchResults React element.
 */
const SearchResults = ({
  currentPagination,
  setCurrentPagination,
  maxPagination,
  setMaxPagination,
  results,
  totalResults
}) => {
  if (typeof window === 'undefined') {
    // Ensure proper server rendering.
    return null;
  }
  const pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth; // TODO: Move that to context to pass it as props to other components.

  // Cleanup results
  const cleanResults = results.map(result => {
    return {
      title: {
        title: result.title.raw
      },
      fields: {
        slug: result.slug.raw
      },
      contenttype: result.contenttype.raw,
      summary: result.summary.raw,
      taxonomies: result.taxonomylist.raw,
      ratings: (result.ratings || 0).raw ? parseInt(result.ratings.raw) : 0
    };
  });

  // Split the content into insights and other content
  const insights = cleanResults.filter(item => item.contenttype === 'insight');

  const otherContent = cleanResults
    .filter(item => item.contenttype !== 'insight')
    .map((item, i) => {
      return (
        <li key={`${createSlug(item.title.title)}-${i}`} className="rc-margin-bottom--md">
          <Link to={`/${item.fields.slug}`} className="rc-styled-link rc-intro rc-margin-bottom--none">
            {item.title.title}
          </Link>
          <p className="rc-margin-top--none">{item.summary || ''}</p>
        </li>
      );
    });

  return (
    <>
      <LayoutContainer additionalClass="rc-max-width--xl">
        <Column>
          <InsightCardsGrid
            cards={insights}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            maxPagination={maxPagination}
            setMaxPagination={setMaxPagination}
            pageWidth={pageWidth}
            fromSwifttype
          />
          {insights.length === 0 && (
            <h3 className="rc-large-intro rc-text--center rc-margin-bottom--lg">
              Sorry no insights for that search term...
            </h3>
          )}
        </Column>
      </LayoutContainer>
      <LayoutContainer additionalClass="rc-max-width--md">
        <Column>
          <ul className="rc-list rc-list--blank rc-list--align">{otherContent}</ul>
        </Column>
      </LayoutContainer>
    </>
  );
};

export {SearchResults};
