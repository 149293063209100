import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

const TooltipsContext = createContext([[], () => {}]);

const TooltipsContextProvider = ({children}) => {

  const [tooltipsInstances, setTooltipsInstances] = useState([]);
  return (
    <TooltipsContext.Provider value={[tooltipsInstances, setTooltipsInstances]}>
      {children}
    </TooltipsContext.Provider>
  );
};

TooltipsContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ])
};

export {TooltipsContext, TooltipsContextProvider};
