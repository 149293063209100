import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

/**
 * @description RCDL standard button comoponent.
 * @param {string} tag Define type of button, either button or anchor tag.
 * @param {string} buttonType Define type of button, according to RCDL rules, type one or two.
 * @param {DOMElement} children Any valid react children of button.
 * @param {string} href URL to pass if button is anchor tag.
 * @param {boolean} external Define if button is linking to external page.
 * @param {string} additionalClass Addtional class.
 * @param {function} handleClick Define action on click event.
 * @param {any} rest Any other props.
 * @return {DOMElement} Button React element.
 */
const Button = ({
  tag = 'button',
  buttonType = 'one',
  children,
  href,
  external = false,
  additionalClass = '',
  handleClick,
  ...rest}) => {

  let buttonClass = buttonType === 'one' ? 'rc-btn--one' : 'rc-btn--two';
  buttonClass = `${buttonClass} ${additionalClass}`;

  if (tag === 'button') {
    return (
      <button
        className={`rc-btn ${buttonClass}`}
        onClick={handleClick}
        onSubmit={handleClick}
        {...rest}>{children}</button>
    );
  }
  else {
    if (!external) {
      return <Link className={`rc-btn ${buttonClass}`} to={href} {...rest}>{children}</Link>;
    }
    else {
      return <a className={`rc-btn ${buttonClass}`} target="_blank" rel="noopener noreferrer" href={href}
        {...rest}>{children}</a>;
    }
  }
};

Button.propTypes = {
  tag: PropTypes.oneOf(['button', 'link']),
  buttonType: PropTypes.oneOf(['one', 'two']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  href: PropTypes.string,
  external: PropTypes.bool,
  additionalClass: PropTypes.string,
  handleClick: PropTypes.func
};

export {Button};
