/**
 * @description Helper function to setup a button configuration object. Switch between
 * config types depending on contenty type.
 * @param {string} typeName Content type of link target.
 * @param {string} cardTitle Title of link target.
 * @param {object} fields Node specific fields.
 * @param {string} buttonLabel String to use as a label button.
 * @param {boolean} external Defines if button should link outside of the website.
 * @return {object} Object with button cofiguration.
 */
const setButtonConfig = ({typeName, cardTitle, fields, buttonLabel, external = false}) => {
  let buttonConfig = {};
  switch (typeName) {
    case 'ContentfulSpecies':
      buttonConfig = {
        buttonLabel: buttonLabel || `${cardTitle} insights`,
        slug: '/' + fields.slug,
        buttonType: 'two'
      };
      break;
    case 'ContentfulJourneyDiagram':
      buttonConfig = {
        buttonLabel: buttonLabel || `${cardTitle}`,
        slug: '/' + fields.slug,
        buttonType: 'two'
      };
      break;
    case 'ContentfulPage':
      buttonConfig = {
        buttonLabel: buttonLabel || 'Explore',
        slug: '/' + fields.slug,
        buttonType: 'two'
      };
      break;
    case 'ContentfulStage':
      buttonConfig = {
        buttonLabel,
        slug: '/' + fields.slug,
        buttonType: 'two'
      };
      break;
    case 'custom':
      buttonConfig = {
        buttonLabel,
        slug: fields.slug,
        buttonType: 'two',
        external
      };
      break;
  }
  return buttonConfig;
};

export default setButtonConfig;
