import React, {useRef, useEffect, useContext} from 'react';
import tippy from 'tippy.js';
import PropTypes from 'prop-types';
import {TooltipsContext} from '../../contexts/tooltipsContext';

/**
 * @description Tooltip component build with RCDL.
 * @param {DOMElement} children Content of tooltip.
 * @param {DOMElement} button Any valid DOM element that can be used as a tooltip trigger.
 * @param {string} trigger Defines how tooltip should be triggered.
 * @param {string} placement Position of tooltip content block in relation to button.
 * @param {boolean} hideOnClick Defines if tooltip should be hidden on click event outside of the tooltip.
 * @return {DOMElement} Tooltip React element.
 */
const Tooltip = ({
  children,
  button,
  trigger = 'click',
  placement = 'bottom',
  hideOnClick = true,
  theme = 'brand4',
  triggerClass = '',
  id,
  buttonStyles = {},
  interactiveButton = false,
  notabutton = false,
  onShow = () => {},
  onHide = () => {},
  ...rest
}) => {

  const triggerElement = useRef(null);
  const contentElement = useRef(null);
  const [tooltipsInstances, setTooltipsInstances] = useContext(TooltipsContext);

  useEffect(() => {
    setTooltipsInstances(state => [
      ...state,
      tippy(triggerElement.current,
        {
          content: contentElement.current.childNodes[0],
          arrow: true,
          interactive: true,
          theme: `${theme} rc-${theme}`,
          trigger,
          hideOnClick,
          onShow,
          onHide,
          placement,
          popperOptions: {
            placement,
            modifiers: {
              flip: {
                behavior: ['right', 'bottom']
              },
              preventOverflow: {
                boundariesElement: document.body
              }
            }
          }
        }
      )]);
  }, [triggerElement.current]);

  function layoutSelector(){
    if (interactiveButton !== false){
      if(notabutton === false){
        return (
          <>
            <button data-tooltip={id} ref={triggerElement} className={triggerClass} style={buttonStyles} {...rest}>
              {button}
            </button>
            <div id={id} className="rc-tooltip" ref={contentElement}>{children}</div>
          </>
        );
      }else {
        return (
          <>
            <div data-tooltip={id} ref={triggerElement} className={triggerClass} style={buttonStyles}></div>
            <div id={id} className="rc-tooltip" ref={contentElement}>{children}</div>
          </>
        );
      }
    } else {
      return (
        <>
          <div data-tooltip={id} ref={triggerElement} className={triggerClass}>
            {button}
          </div>
          <div id={id} className="rc-tooltip" ref={contentElement}>{children}</div>
        </>
      );
    }
  }

  return layoutSelector();
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  button: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  trigger: PropTypes.oneOf(['mouseenter', 'focus', 'click', 'manual']),
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  hideOnClick: PropTypes.bool,
  theme: PropTypes.string,
  triggerClass: PropTypes.string,
  id: PropTypes.string,
  notabutton:PropTypes.bool,
  buttonStyles: PropTypes.object,
  interactiveButton: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func
};

export {Tooltip};
