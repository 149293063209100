import React from 'react';
import PropTypes from 'prop-types';

import {Button} from '.';

/**
 * @description Component used accross various content blocks. Contains title, text and optional meta tags and buttons.
 * @param {string} title Title, header text.
 * @param {string} text Copy text.
 * @param {array} buttonsConfig Array that contains objects with button configuration.
 * @param {string} buttonTag Define type of button, either button or anchor tag.
 * @param {string} wrapperClass Class passed to block wrapper.
 * @param {string} meta Optional meta tags to display above the title.
 * @return {DOMElement} SingleBreadcrumb React element.
 */
const TextContentBlock = ({
  title,
  text,
  buttonsConfig,
  buttonTag = 'link',
  wrapperClass = '',
  meta,
  style
}) => {

  /**
   * @description Renders buttons if cofiguration exists.
   * @return {array} Array with button elements.
   */
  const renderButtons = () => {
    return buttonsConfig.map((button, i) => {
      const marginClass = i + 1 === buttonsConfig.length ? '' : 'rc-margin-right--xs';
      return (
        <Button
          additionalClass={marginClass}
          key={button.slug}
          href={button.slug}
          buttonType={button.buttonType}
          tag={buttonTag}
          external={button.external}>{button.buttonLabel}</Button>
      );
    });
  };
  return (
    <div className={wrapperClass} style={style}>
      {meta && <span className="rc-meta">{meta}</span>}
      <h2 className="rc-beta">{title}</h2>
      <p>{text}</p>
      {buttonsConfig && buttonsConfig[0].buttonLabel && renderButtons()}
    </div>
  );
};

TextContentBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonTag: PropTypes.oneOf(['button', 'link']),
  wrapperClass: PropTypes.string,
  buttonsConfig: PropTypes.array,
  meta: PropTypes.string,
  style: PropTypes.object
};

export {TextContentBlock};
