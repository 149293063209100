import {useStaticQuery, graphql} from 'gatsby';

/**
 * @description Custom hook. Query mega menu navigation from gatsby nodes.
 * @return {object} Object with mega menu navigation data.
 */
const useMegaMenuNavigation = () => {
  const {allContentfulNavigation} = useStaticQuery(graphql`
    query {
      allContentfulNavigation(filter: {
        title: {
          eq: "Mega menu"
        }
      }) {
        edges {
          node {
            title
            navigationList {
              id
              ... on ContentfulNavigationList {
                
                 navigationHeader {
                  __typename
                  ... on ContentfulPage {
                    id
                    title
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                  ... on ContentfulSpecies {
                    id
                    title
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                  ... on ContentfulJourneyDiagram{
                    id
                    title
                    fields {
                      slug
                    }
                  }
                }
                navigationItem {
                  __typename
                  ... on ContentfulStage {
                    id
                    title
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                  ... on ContentfulPage {
                    id
                    title
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                  ... on ContentfulSpecies {
                    id
                    title
                    navigationLabel
                    fields {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return allContentfulNavigation.edges;
};

export {useMegaMenuNavigation};
