const React = require('react');
const AppSearchAPIConnector = require('@elastic/search-ui-app-search-connector');
const {Auth, isLoggedIn} = require('./src/components/templates/Auth');
const {Header, Footer} = require('./src/components/organisms');
const {SearchProvider, WithSearch} = require('@elastic/react-search-ui');

const {RCDLStateProvider, rcdlInitialState, rcdlReducer, RCDLStateContext} = require('./src/contexts/RCDLcontext');
const {HomeSearchProvider, useHomeSearchHandles} = require('./src/contexts/searchContext');
const {Location} = require('@reach/router');

// Setup Swifttype search connection
// TODO: Move to separate file and import
const connector = new AppSearchAPIConnector.default({
  searchKey: process.env.GATSBY_SWIFTTYPE_SEARCH_KEY,
  engineName: process.env.GATSBY_SWIFTTYPE_ENGINE_NAME,
  hostIdentifier: process.env.GATSBY_SWIFTTYPE_IDENTIFIER
});

const config = {
  apiConnector: connector,
  searchQuery: {},
  trackUrlState: false,
  autocompleteResults: {
    results: {
      result_fields: {
        title: {
          snippet: {
            size: 55,
            fallback: true
          }
        },
        nps_link: {
          raw: {}
        }
      }
    }
  }
};

// Separate template type added to create specific versions of some pages, that are 'outside'
// of the main website - no navigation and other internal links, no Auth etc.

const PageWrap = ({element, hasAuth}) => {
  return (
    <RCDLStateProvider initialState={rcdlInitialState} reducer={rcdlReducer}>
      <HomeSearchProvider>
        <SearchProvider config={config}>
          <WithSearch
            mapContextToProps={({setFilter, clearFilters}) => ({
              setFilter,
              clearFilters
            })}
          >
            {({setFilter, clearFilters}) => {
              const {filterValue} = useHomeSearchHandles();

              React.useEffect(() => {
                if (filterValue && filterValue !== 'all') {
                  setFilter('taxonomylist', filterValue.toLowerCase(), 'any');
                }
                else {
                  clearFilters();
                }
              }, [filterValue]);

              return (
                <RCDLStateContext.Consumer>
                  {context => (
                    <Location>
                      {({location}) => {
                        let modifier = typeof location.action !== 'undefined' ? location.action : ''; // So we can detect forced rerenders.
                        if (location.href + modifier !== context[0].lastPage) {
                          context[0].triggerPageCount(context[0].pageHasChanged);
                          context[0].setPage(location.href + modifier);
                        }
                      }}
                    </Location>
                  )}
                </RCDLStateContext.Consumer>
              );
            }}
          </WithSearch>
          <Header separate={!hasAuth} />
          {element}
          <Footer separate={!hasAuth} />
        </SearchProvider>
      </HomeSearchProvider>
    </RCDLStateProvider>
  );
};

// eslint-disable-next-line react/display-name, react/prop-types
exports.wrapPageElement = ({element}) => {
  if (element.props.pageContext.template === 'journey-diagram-separate') {
    return <PageWrap element={element} />;
  }
  else {
    return (
      <Auth>
        <PageWrap element={element} hasAuth />
      </Auth>
    );
  }
};

/**
 * @description Every time the user changes route, we push an object to the data layer.
 */
exports.onRouteUpdate = () => {
  (async () => {
    // This waits for the logged in response to be returned.
    const loggedInValue = await isLoggedIn();
    setTimeout(async () => {
      let url = window.location.href;
      let baseUrl = location.origin;
      let newUrl = url.replace(baseUrl, '');
      let pageTitle = document.title;
      let dataLayer = window.dataLayer = window.dataLayer || [];
      const loginStatus = loggedInValue();
      // If url is base url and user is not logged in.
      if (newUrl === '/' && loginStatus === 'not-logged') {
        newUrl = '/login';
        pageTitle = 'Login | Pet Owner Insights';
      }
      // If url is base url and user is logged in.
      else if (newUrl === '/' && loginStatus === 'logged' || url.includes('?token')) {
        newUrl = '/home';
        pageTitle = 'Homepage | Pet Owner Insights';
      }
      // If url is anything other than base url.
      dataLayer.push({
        event: 'virtualPageView',
        page: {
          virtualPageURL: newUrl,
          virtualPageTitle: pageTitle
        }
      });
    }, 500);
  })()
};
