// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-templates-insight-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/insight.js" /* webpackChunkName: "component---src-page-templates-insight-js" */),
  "component---src-page-templates-journey-diagram-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/journey-diagram.js" /* webpackChunkName: "component---src-page-templates-journey-diagram-js" */),
  "component---src-page-templates-species-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/species.js" /* webpackChunkName: "component---src-page-templates-species-js" */),
  "component---src-page-templates-quantifying-insights-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/quantifying-insights.js" /* webpackChunkName: "component---src-page-templates-quantifying-insights-js" */),
  "component---src-page-templates-about-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/about.js" /* webpackChunkName: "component---src-page-templates-about-js" */),
  "component---src-page-templates-homepage-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/homepage.js" /* webpackChunkName: "component---src-page-templates-homepage-js" */),
  "component---src-page-templates-stages-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/stages.js" /* webpackChunkName: "component---src-page-templates-stages-js" */),
  "component---src-page-templates-contact-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/contact.js" /* webpackChunkName: "component---src-page-templates-contact-js" */),
  "component---src-page-templates-stage-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/page_templates/stage.js" /* webpackChunkName: "component---src-page-templates-stage-js" */),
  "component---src-pages-404-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jmdrawneek/Sites/react_royalcanin_insights-canvas/.cache/data.json")

