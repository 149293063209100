/**
 * @description Calculate average value of ratings
 * @param {array} ratings Array of objects with ratings information.
 * @return {number} Average rating value.
 */
const calculateRating = (ratings) => {
  const sum = ratings.reduce((acc, rating) => acc + rating.ratingValue, 0);
  return Math.round(sum / ratings.length);
};
export default calculateRating;
