import React from 'react';
import PropTypes from 'prop-types';

import joinClassNames from '../../helpers/joinClassNames';

/**
 * @description Layout container, wrapper for columns. Main layout item for RCDL.
 * @param {string} columns Number of columns inside of the container.
 * @param {DOMElement} children Any valid DOM element.
 * @param {boolean} stacked If stacked, columns are going to be displayed vertically instead of horizontally.
 * @param {string} align Defines how columns should be vertically aligned.
 * @param {string} textAlign Defines how text inside of columns should be horizontally aligned.
 * @param {string} additionalClass Any addtional classes.
 * @param {any} rest Any other props.
 * @return {DOMElement} Layout Container React element.
 */
const LayoutContainer = ({
  columns = 'two',
  children,
  stacked = false,
  align,
  textAlign,
  additionalClass = '',
  ...rest
}) => {
  const columnsClass = `rc-${columns}-column`;
  const alignClass = align ? `rc-content-h-${align}` : '';
  const textAlignClass = textAlign ? `rc-text--${textAlign}` : '';
  const stackedClass = stacked ? 'rc-stacked' : '';
  const fullClass = joinClassNames([columnsClass, alignClass, stackedClass, additionalClass, textAlignClass]);

  return (
    <div className={`rc-layout-container ${fullClass}`} {...rest}>
      { children }
    </div>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  columns: PropTypes.oneOf(['one', 'two', 'three', 'four', 'five', 'six']),
  stacked: PropTypes.bool,
  align: PropTypes.oneOf(['top', 'bottom', 'middle']),
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  additionalClass: PropTypes.string
};

export {LayoutContainer};
