import React, {useRef} from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import objectFitImages from 'object-fit-images';
import detectIE from '../../helpers/detectIE';

/**
 * @description Wrapper component for super performant gatsby-image component.
 * @param {data} data Data of the image taken from CMS.
 * @param {object} style Addtional style passed to component.
 * @param {string} crop Type of cropping method.
 * @param {any} rest Any other props.
 * @return {DOMElement} Image React element.
 */
const Image = ({data, style, crop = 'fill', additionalClass, ...rest}) => {
  let image = null;
  let browser = {name: 'notIE', version: 'new'}; // ToDo: refactor into global function.

  if (typeof window !== 'undefined') {
    browser = detectIE();
  }

  if (browser.name === 'ie' && browser.version === 11) {
    const imageRef = useRef(null);

    image = <img
      className={additionalClass}
      ref={imageRef}
      src={data.fixed.src.replace(/&fit=fill/g, `&fit=${crop}`)} // ToDo: figure out how to change the query.
      width={data.fixed.width}
      height={data.fixed.height}
      alt=""
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: '50% 50%',
        fontFamily: 'object-fit: cover; object-position: 50% 50%'}}
      {...rest}
    />;
    objectFitImages(imageRef.current);
  }
  else {
    if (data !== null && data !== 'undefined') {
      if (data.fluid.src !== null && data.fluid.src !== 'undefined') {
        image = <Img fluid={data.fluid} style={style} className={additionalClass} {...rest}/>;
      }
      else {
        image = <object data={data.file.url} type="image/svg+xml"></object>;
      }
    }
  }
  return (
    data && image
  );
};

Image.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  crop: PropTypes.string,
  additionalClass: PropTypes.string
};

export {Image};
