import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description Single tab element.
 * @param {object} data Information about tab to display.
 * @param {function} onClick Method to invoke on Click event.
 * @param {string} activeTab ID/name for current active tab.
 * @return {DOMElement} Tab React element.
 */
const Tab = ({value, label, onClick, activeTab}) => {
  return (
    <li>
      <button
        className="rc-tab rc-btn"
        role="tab"
        onClick={() => onClick(value)}
        aria-selected={activeTab === value}>{label}</button>
    </li>
  );
};

Tab.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  activeTab: PropTypes.string
};

export {Tab};
