import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import checkLogin from '../../helpers/checkLogin';
import getToken from '../../helpers/getToken';
import {SEO, SiteEnvVars} from '../organisms';
import {LoadingScreen} from '../common';
import {Login} from '.';

/**
 * @description Returns logged in response from checkLogin function.
 * @return {String} Returns a string of the logged in response (logged / not-logged).
 */
async function loggedResponse() {
  let {token, tokenFromStorage} = getToken();
  // TODO: Check for localStorage before using it
  // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available

  // checkLogin passes an existing token to lambda function to check its validity.
  // If returns true, the token is valid.
  // If false the token is not valid, and we need to get a new token from
  // getToken.token and then check it.
  const response = await checkLogin(token);
  if (!response) {
    if (tokenFromStorage) {
      localStorage.clear();
      // Waits until checkLogin returns a value before executing.
      const secondResponse = await checkLogin(getToken().token);
      return secondResponse ? 'logged' : 'not-logged';
    }
    else {
      return 'not-logged';
    }
  }
  return 'logged';
}

/**
 * @description Returns the logged in response and stores it. Then returns the value to be accessed later.
 * @return {String} Value returned from the loggedResponse function, never changes.
 */
async function isLoggedIn() {
  // This only runs once
  const awaitedResponse = await loggedResponse();
  // Once the above has run, this function holds the returned value
  const closureResponse = () => {
    const returnedClosureResponse = awaitedResponse;
    // This is the returned value from above, this will never change.
    return returnedClosureResponse;
  };
  return closureResponse;
}

/**
 * @description Handles authentication proccess. Checks if there's a token in local storage
 * and if there is - is it stored in AWS S3 bucket.
 * @param {DOMElement} children Website content.
 * @return {DOMElement} Auth React element.
 */
const Auth = ({children}) => {

  const [loginStatus, setLoginStatus] = useState();

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const callingFunctions = await isLoggedIn();
      setLoginStatus(callingFunctions());
    };
    checkIsLoggedIn();
  }, []);

  switch (loginStatus) {
    case 'logged':
      return (
        <>
          <SiteEnvVars />
          {children}
        </>
      );
    case 'not-logged':
      return (
        <>
          <SiteEnvVars />
          <SEO title="Royal Canin Pet Owner Insights" />
          <Login />
        </>
      );
    default:
      return (
        <>
          <SiteEnvVars />
          <LoadingScreen />
        </>
      );
  }
};

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ])
};

export {Auth, isLoggedIn};
