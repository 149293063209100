import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {WithSearch} from '@elastic/react-search-ui';

import modifyClass from '../../helpers/modifyClass';
import {onFormSubmit} from '../../helpers/searchFormHandlers';
import {SearchInputBar} from './SearchInputs';

/**
 * @description Displays search modal to wrap search input and result list.
 * @param {boolean} isOpen Defines if search modal is open or close.
 * @param {function} toggleModal Function to handle opening/closing of navigation modal.
 * @param {object|array} RCDLState Object that describes load state of RCDL assets.
 * @return {DOMElement} Search Modal React element.
 */
const SearchModal = ({isOpen, toggleModal, RCDLState}) => {
  let modalClass = !isOpen ? 'rc-hidden' : '';

  useEffect(() => {
    let action = isOpen ? 'add' : 'remove';
    if (typeof RCDL.features.ToggleGroup !== 'undefined') {
      modifyClass(action, document.body, 'rc-scroll--none');
      RCDL.features.ToggleGroup.init('[data-toggle-group]');
    }
  }, [RCDLState]);

  if (typeof window !== 'undefined') {
    return (
      <WithSearch
        mapContextToProps={({setSearchTerm, searchTerm, autocompletedResults}) => ({
          setSearchTerm,
          searchTerm,
          autocompletedResults
        })}
      >
        {({setSearchTerm, searchTerm, autocompletedResults}) => (
          <>
            <form
              className={`rc-header__search-bar ${modalClass}`}
              autoComplete="off"
              onSubmit={async e => {
                await onFormSubmit({
                  event: e,
                  searchTerm: searchTerm,
                  RCDLState: RCDLState,
                  toggleModal: toggleModal
                });
              }}
            >
              <button
                className="rc-btn rc-btn--icon rc-icon rc-search--xs rc-iconography rc-stick-left rc-vertical-align"
                type="submit"
                aria-label="Search"
              >
                <span className="rc-screen-reader-text">Search</span>
              </button>
              <SearchInputBar
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                autocompletedResults={autocompletedResults}
                liClasses="rc-padding-y--xs rc-padding-x--sm"
                ulClasses="rc-list rc-list--align rc-list--blank rc-bg-colour--brand4 rc-padding--xs"
              />
              <label htmlFor="search-box-2">
                <span className="rc-screen-reader-text">Start typing to search</span>
              </label>
              <button
                className="rc-btn rc-btn--icon rc-icon rc-close--xs rc-iconography rc-stick-right rc-vertical-align"
                type="button"
                aria-label="Close"
                data-click-modifier=".rc-hidden"
                data-search-init="active"
                onClick={() => toggleModal(false)}
              >
                <span className="rc-screen-reader-text">Close</span>
              </button>
            </form>
            {isOpen && <div className="rc-shade" onClick={() => toggleModal(false)} />}
          </>
        )}
      </WithSearch>
    );
  }
  return null;
};

SearchModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  RCDLState: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export {SearchModal};
