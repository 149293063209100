import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import {TextContentBlock, Image} from '../common';
import {LayoutContainer, Column} from '../layouts';

const Opportunity = ({opportunities}) => {

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulAsset(
          filter: {
            title: {
              eq: "food-for-thought"
            }
          }
        ) {
          edges {
            node {
              title
              fluid {
                base64 
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(width: 800, height: 600) {
                src
                width
                height
              }
            }
          }
        }
      }
    `
  );

  const mergeOpportunities = () => {
    return (
      <ul className="rc-list rc-list--align rc-padding-left--md--mobile">
        {
          opportunities.map(item => {
            return (
              <li className="rc-list__item" key={item.id}>
                {item.title}
              </li>
            );
          })
        }
      </ul>
    );
  };

  return (
    <LayoutContainer
      additionalClass="rc-max-width--lg rc-margin-top--lg rc-margin-bottom--xl rc-reverse-layout-mobile rc-padding-x--md">
      <Column align="middle">
        <TextContentBlock
          wrapperClass="rc-padding-right--md rc-large-body"
          title={'Food for thought'}
          text={mergeOpportunities()}/>
      </Column>
      <Column>
        <Image
          data={data.allContentfulAsset.edges[0].node}
          alt='Food for thought'
          additionalClass="rc-margin-bottom--lg--mobile"
          crop={'pad'}
        />
      </Column>
    </LayoutContainer>
  );
};

Opportunity.propTypes = {
  opportunities: PropTypes.array
};

export {Opportunity};

