import React from 'react';
import PropTypes from 'prop-types';

import {LayoutContainer, Column} from '../layouts';
import {ContactForm} from '.';
import {RCDLStateContext} from '../../contexts/RCDLcontext';


/**
 * @description Component used as a wrapper for contact form and description.
 * @param {string} pageTitle Title of current page. Used for contact form message.
 * @return {DOMElement} Contribute Container React element.
 */
const Contribute = ({pageTitle}) => {
  return (
    <LayoutContainer additionalClass="rc-padding-bottom--lg rc-max-width--lg">
      <Column align="middle" additionalClass="rc-padding-lg">
        <div className="rc-padding--md">
          <h2 className="rc-gamma">Need more detail?</h2>
          <p>
            If you&apos;d like more detail on this insight, let us know how we can help and
            we&apos;ll get back to you as soon as possible.
          </p>
        </div>
      </Column>
      <Column>
        <div className="rc-padding-right--md">
          <RCDLStateContext.Consumer>
            {(context) =>
              <ContactForm RCDLState={context} pageTitle={pageTitle}/>
            }
          </RCDLStateContext.Consumer>
        </div>
      </Column>
    </LayoutContainer>
  );
};

Contribute.propTypes = {
  pageTitle: PropTypes.string
};

export {Contribute};
