import React from 'react';
import PropTypes from 'prop-types';
import {Element} from 'react-scroll';
import ViewportObserver from 'viewport-observer';
import 'intersection-observer'; // Intsersection observer polyfill for IE.

const JourneySections = ({currentStage, handleIntersection}) =>  {

  const svgSections = document.getElementsByClassName('diagram-section');
  const diagram = document.getElementsByClassName('journey-diagram-svg');

  const pageHeight = window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight; // TODO: Move that to context to pass it as props to other components

  // Calucalates 1px container in the middle of page as intersection root
  const observerMargin = `${Math.floor((pageHeight - 1) / 2)}px`;

  if (diagram[0]) {
    const parentHeight = diagram[0].getBoundingClientRect().height;

    return Array.from(svgSections).map(el => {
      const elHeight = el.getBoundingClientRect().height;
      const sectionStyle = {
        height: `${elHeight / parentHeight * 100}%`,
        background: el.id === currentStage ? '#FAF9FB' : '',
        transition: '0.3s', width: '100%'
      };

      function capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

      return (
        <ViewportObserver
          key={elHeight}
          style={sectionStyle}
          id={el.id}
          onChange={handleIntersection}
          rootMargin={`-${observerMargin} 0px -${observerMargin} 0px`}>
          <Element name={el.id} style={{height: '100%', width: '100%'}}>
            {
              <h2 className={'rc-beta rc-md-up'} style={{paddingTop:'1rem', paddingLeft:'1rem'}}>{capitalize(el.id)}</h2> 
            }
          </Element>
        </ViewportObserver>
      );
    });
  }
};

JourneySections.propTypes = {
  currentStage: PropTypes.string,
  handleIntersection: PropTypes.func
};
export {JourneySections};
