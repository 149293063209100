import React from 'react';
import PropTypes from 'prop-types';

import formStyles from '../../styles/forms.module.css';

/**
 * @description Component for displaying text based form inputs.
 * @param {function} onChange Methodd to invoke when value of input changes.
 * @param {string} type Type of input.
 * @param {string} label Label to display inside of input.
 * @param {string} id ID for connecting label to input.
 * @param {string} additionalClass Any addtional classes.
 * @param {string} value Value of input.
 * @param {string} name Unique input name.
 * @return {DOMElement} Text Field React element.
 */
const TextField = ({ // TODO: Move text area to separate component
  onChange,
  type = 'text',
  label = 'Text field',
  id = 'id-text',
  additionalClass = '',
  value,
  name,
  ...rest
}) => {

  let InputTag = null;
  let inputClass = null;
  let labelClass = null;
  let labelWrapperClass = null;
  const stateClass = value ? formStyles.withValue : formStyles.noValue;
  switch (type) {
    case 'textarea':
      InputTag = 'textarea';
      inputClass = 'rc-input__textarea';
      labelClass = formStyles.textAreaLabel;
      labelWrapperClass = formStyles.textAreaLabelWrapper;
      break;
    default:
      InputTag = 'input';
      inputClass = 'rc-input__control';
      labelClass = '';
      labelWrapperClass = '';
  }

  return (
    <span className={`rc-input ${additionalClass}`} {...rest}>
      <InputTag
        className={`${inputClass} ${stateClass}`}
        id={id}
        type={type}
        name={name || type}
        onChange={onChange}
        value={value}/>
      <label className={`rc-input__label ${labelWrapperClass}`} htmlFor={id}>
        <span className={`rc-input__label-text ${labelClass}`}>{label}</span>
      </label>
    </span>
  );
};

TextField.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['text', 'email', 'textarea']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  label: PropTypes.string,
  id: PropTypes.string,
  additionalClass: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string
};

export {TextField};
