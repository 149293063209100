import React from 'react';
import PropTypes from 'prop-types';

import {Fade, RadioButtons, Tab} from '../common';
import {OwnerRoleTooltip} from '.';
import journeyStagesStyles from '../../styles/journeyStages.module.css';
import createSlug from '../../helpers/createSlug';

/**
 * @description Displays title and description for current stage on the journey diagram page.
 * Wrapped in fade element to add animation on stage change.
 * @param {arra} stages Array of objects with stages data.
 * @param {string} currentStage Name of current stage.
 * @param {string} title Page title.
 * @return {DOMElement} Journey Stages React element.
 */
const JourneyStages = ({stages, currentStage, title, views, currentView, setCurrentView}) => {

  const renderTabs = () => {
    return views.map(view => {
      return (
        <Tab
          key={view.value}
          value={view.value}
          label={view.label}
          activeTab={currentView}
          onClick={setCurrentView}/>
      );
    });
  };

  return (
    <div className={journeyStagesStyles.wrapper}>
      {
        stages.map((node) => {
          return (
            <Fade
              key={node.id}
              visible={createSlug(node.title) === currentStage}
              additionalStyle={{height: '100%', position: 'absolute', top: 0, left: 0, width: '100%'}}>
              <div className={journeyStagesStyles.itemWrapper}>
                <p className="rc-meta rc-margin-bottom--xs">{title}</p>
                <h2 className={`rc-md-down rc-beta ${journeyStagesStyles.itemHeader}`}>{node.title}</h2>
                {node.ownerRoleReference && <OwnerRoleTooltip data={node.ownerRoleReference}/>}
                <p className="rc-lg-up">{node.summary.summary}</p>
                <form className="rc-md-up">
                  <RadioButtons values={views} selected={currentView} handleChange={setCurrentView}/>
                </form>
                <ul
                  className={`rc-md-down rc-scroll--x rc-list rc-list--inline rc-list--align rc-list--blank rc-tab--centered ${journeyStagesStyles.tabs}`}
                  role="tablist">
                  {renderTabs()}
                </ul>
              </div>
            </Fade>
          );
        })
      }
    </div>
  );
};

JourneyStages.propTypes = {
  stages: PropTypes.array,
  currentStage: PropTypes.string,
  title: PropTypes.string,
  views: PropTypes.array,
  currentView: PropTypes.string,
  setCurrentView: PropTypes.func
};

export {JourneyStages};
