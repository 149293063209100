import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

/**
 * @description Component that displays label button with icon.
 * @param {string} tag Defines type of button, either button or anchor tag.
 * @param {string} type Defines if button should use regular icon or flag icon.
 * @param {string} size Size of the icon, according to RCDL rules.
 * @param {string} colour Defines colour of icon, according to RCDL rules.
 * @param {string} icon Name of the wanted icon. Icons name taken from RCDL icons list.
 * @param {DOMElement} children Any valid react children of button.
 * @param {string} href URL to pass if button is anchor tag.
 * @param {boolean} external Define if button is linking to external page.
 * @param {string} additionalClass Addtional class.
 * @param {boolean} label Defines if text label shoudl be added.
 * @param {function} handleClick Defines action on click event.
 * @param {boolean} customLabelButton Defines if button should use default rc-btn class or not.
 * @param {any} rest Any other props.
 * @return {DOMElement} Label Button React element.
 */

const LabelButton = ({
  tag = 'button',
  type = 'icon',
  size = 'xs',
  colour = 'iconography',
  icon,
  children,
  href,
  external = false,
  additionalClass,
  label = true,
  handleClick,
  customLabelButton = false,
  ...rest
}) => {

  let buttonClass = type === 'icon' ? `rc-icon rc-${colour} rc-${icon}--xs` : `rc-flag rc-${icon}`;
  if (!customLabelButton) {
    buttonClass = label ? `rc-btn--icon-label ${buttonClass}` : `rc-btn--icon ${buttonClass}`;
  }
  buttonClass = type === 'flag' && size === 'xs' ? `${buttonClass}--xs` : buttonClass;
  buttonClass = additionalClass ? `${buttonClass} ${additionalClass}` : buttonClass;
  let content = label ? children : `<span class="rc-screen-reader-text">${children}</span>`;

  if (tag === 'button') {
    if (!customLabelButton) {
      return <button
        className={`rc-btn ${buttonClass}`}
        dangerouslySetInnerHTML={{__html: content}} // Used to pass the screen reader span.
        onClick={handleClick}
        {...rest}>
      </button>;
    }
    else {
      return <button
        className={`${buttonClass}`}
        dangerouslySetInnerHTML={{__html: content}} // Used to pass the screen reader span.
        onClick={handleClick}
        {...rest}>
      </button>;
    }

  }
  else {
    if (!external) {
      return <Link
        className={`rc-btn ${buttonClass}`}
        to={href}
        dangerouslySetInnerHTML={{__html: content}}>
      </Link>;
    }
    else {
      return <a
        className={`rc-btn ${buttonClass}`}
        href={href}
        dangerouslySetInnerHTML={{__html: content}}>
      </a>;
    }
  }
};

LabelButton.propTypes = {
  tag: PropTypes.oneOf(['button', 'link']),
  type: PropTypes.oneOf(['icon', 'flag']),
  size: PropTypes.oneOf(['xs', 'lg']),
  colour: PropTypes.oneOf(['iconography', 'brand3']),
  icon: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]),
  href: PropTypes.string,
  external: PropTypes.bool,
  additionalClass: PropTypes.string,
  label: PropTypes.bool,
  handleClick: PropTypes.func
};

export {LabelButton};
