/**
 * @description Take a string and capitalize each letter as title case
 * @param {string} string Mixed case string to be converted.
 * @return {string} String converted to title case.
 */
export default string =>
  string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
