import React from 'react';
import PropTypes from 'prop-types';
import {SEO} from '../organisms';

/**
 * @description Wrap page main content in main element, and adds SEO component for adding meta description, title etc.
 * @param {DOMElement} children Main content of the pages.
 * @param {object} pageContext Page data passed from gatsby queries.
 * @return {DOMElement} PageWrapper React element.
 */
const PageWrapper = ({children, pageContext}) => {
  return (
    <main className="rc-content--fixed-header">
      <SEO title={pageContext.title}/>
      {children}
    </main>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.array
  ]),
  pageContext: PropTypes.object
};

export {PageWrapper};
