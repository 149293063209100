import React from 'react';
import {Helmet} from 'react-helmet';

/**
 * @description Wrap page main content in main element, and adds SEO component for adding meta description, title etc.
 * @param {DOMElement} children Main content of the pages.
 * @param {object} pageContext Page data passed from gatsby queries.
 * @return {DOMElement} PageWrapper React element.
 */
const SiteEnvVars = () => {
  // This pushes environment variables to the data layer.
  return (
    <Helmet>
      <script>
        {`
          let dataLayer = window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            site: {
              id: 'RCGlobalIC1',
              environment: 'stg',
              country: 'Global'
            }
          });
        `}
      </script>
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NCWZQ3');
        `}
      </script>
    </Helmet>
  );
};

export {SiteEnvVars};
