import {useState} from 'react';

/**
 * @description Custom hook to control values gathering and submitting from contact form.
 * @param {function} callback Function to invoke on form submit.
 * @return {object} Object with values and methods to invoke on value change and form submit.
 */
function useForm(callback) {
  let [values, setValues] = useState({});

  const handleSubmit = e => {
    if (e) {
      e.preventDefault();
      callback();
    }
  };

  const handleChange = (e, cleanUp) => {
    if (cleanUp) {
      return setValues({});
    }
    e.persist();
    setValues(fields => {
      return {
        ...fields,
        [e.target.name]: e.target.value
      };
    });
  };

  return {
    handleSubmit,
    handleChange,
    values
  };
}

export default useForm;
