import React, {createContext, useContext, useState} from 'react';

const HomeSearchContext = createContext([{}, () => {}]);

const HomeSearchProvider = ({children}) => {
  const [state, setState] = useState({
    filterValue: ''
  });
  return <HomeSearchContext.Provider value={[state, setState]}>{children}</HomeSearchContext.Provider>;
};

const useHomeSearchHandles = () => {
  const [state, setState] = useContext(HomeSearchContext);

  function setSearchResultFilter(value) {
    setState(state => {
      return {
        ...state,
        filterValue: value
      };
    });
  }

  return {
    setSearchResultFilter,
    filterValue: state.filterValue
  };
};

export {HomeSearchContext, HomeSearchProvider, useHomeSearchHandles};
