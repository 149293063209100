import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import {useMainNavigation} from '../../static_queries';

/**
 * @description Displays top navigation in main header.
 * @param {boolean} separate Defines if should create standard or separate version of the navigtion.
 * @return {DOMElement} Top Navigation React element.
 */
const TopNavigation = ({separate}) => {

  const navigationLinks = useMainNavigation()[0];
  let items = [];

  if (!separate) {
    items = navigationLinks.node.navigationList[0].navigationItem;
  }
  else {
    items = [
      {
        fields: {
          slug: 'dog/journey/the-puppy-owner-journey-separate'
        },
        id: 1,
        navigationLabel: 'Puppy Owner Journey'
      },
      {
        fields: {
          slug: 'cat/journey/the-kitten-owner-journey-separate'
        },
        id: 2,
        navigationLabel: 'Kitten Owner Journey'
      }
    ];
  }

  return (
    <nav className="rc-header__nav rc-header__nav--secondary rc-md-up ">
      <ul className="rc-list rc-list--blank rc-list--inline rc-list--align rc-header__center">
        {
          items.map((item) => {
            return (
              <li className="rc-list__item" key={item.id}>
                <ul className="rc-list rc-list--blank rc-list--inline rc-list--align rc-header__center">
                  <li className="rc-list__item">
                    <Link className="rc-list__header"
                      to={`/${item.fields.slug}`}>{item.navigationLabel || item.title}</Link>
                  </li>
                </ul>
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
};

TopNavigation.propTypes = {
  separate: PropTypes.bool
};

export {TopNavigation};
