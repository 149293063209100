import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {onFormSubmit} from '../../helpers/searchFormHandlers';
import {SearchFilter} from './SearchFilter';
import ClickListenerWrapper from './ClickListenerWrapper';
import {useInsights} from '../../static_queries';
import {Link} from 'gatsby';

/**
 * The file contains two form input elements for the search functionality.
 *
 * The first: SearchInputInline. Relatively stand alone and is wrapped in
 * a form. This is used in places such as the bottom of the homepage and search
 * page.
 *
 * The second: SearchInputBar. A drop in organism that is currently only
 * used within SearchModal.js. It does not contain a form wrapper, as this is
 * added by SearchModal.js
 *
 * It may be worth refactoring of these components and how they work
 * together in the future if new search elements are introduced.
 */

/**
 * When a suggestion link is clicked, to ensure that the module is closed
 * the RCDLState needs to be updated, and the modal has to be closed.
 */
const manageSuggestionClick = () => {
  RCDLState[0].triggerPageCount(RCDLState[0].pageHasChanged + 1);
  toggleModal && toggleModal(false);
};

/**
 * Create a list of suggestions to be used for both input components.
 * Currently uses onMouseEnter/Leave as hangover from old functionality - should
 * be replaced with CSS
 * @param  {bool} active If the element can be actice - are its parents in focus
 * @param  {string} liClasses Custom Li classes
 * @param  {string} ulClasses Custom Ul classes
 * @param  {string} searchTerm The current search term used with Elastic Search
 * @param  {array} autocompletedResults Array of suggested results
 * @return {DOMElement} Component
 */
const renderSuggestions = ({active, liClasses, ulClasses, searchTerm, autocompletedResults}) => {
  const [suggestionHover, setSuggestionHover] = useState(null);

  if (active && autocompletedResults.length > 0 && searchTerm.length > 2) {
    return (
      <ul className={ulClasses} style={{position: 'absolute', zIndex: 999, width: '100%'}}>
        {autocompletedResults.map(item => {
          const title = item.title.raw.length > 55 ? item.title.raw.substring(0, 55) + '...' : item.title.raw;
          return (
            <li className={liClasses} key={item.slug.raw}>
              <Link
                className={`rc-list__item ${suggestionHover === item.slug.raw && 'rc-text-colour--brand1'}`}
                tabIndex="1"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: suggestionHover !== item.slug.raw && 'inherit'
                }}
                to={item.slug.raw}
                onMouseEnter={e => setSuggestionHover(item.slug.raw)}
                onMouseLeave={() => setSuggestionHover('')}
                onClick={() => manageSuggestionClick()}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }
  return null;
};

renderSuggestions.propTypes = {
  active: PropTypes.bool,
  ulClasses: PropTypes.string.isRequired,
  liClasses: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  autocompletedResults: PropTypes.array
};

/**
 * @description Component that displays search term input.
 * @param {string} liClasses Addtional list item classes.
 * @param {string} ulClasses Addtional list classes.
 * @param {func} setSearchTerm Function to set the search term.
 * @param {string} searchterm Current search term from Swifttype.
 * @param {array} autocompletedResults Array of suggested results.
 * @param {object|array} RCDLState Object that describes load state of RCDL assets. Only needed if wrapping input in a form
 * @param {string} currentFilter The filter currently used to filter the results.
 * @param {func} setFilter Function to set the filter for the results.
 * @return {DOMElement} Search input element.
 */
const SearchInputInline = ({
  initialFocus,
  liClasses,
  ulClasses,
  setSearchTerm,
  searchTerm,
  autocompletedResults,
  RCDLState,
  currentFilter,
  setFilter
}) => {
  const [focused, setFocus] = useState(false);

  // Get the insights data - used to create the filter list.
  const insights = useInsights();

  let filterOptionsCreator = {};

  // Grab the taxomomy names for the filter list.
  insights.forEach(item => {
    item.node.taxonomies.map(tax => {
      if (tax.title) {
        filterOptionsCreator[tax.title] = 'a';
      }
      if (tax.speciesName) {
        filterOptionsCreator[tax.speciesName] = 'a';
      }
      if (tax.lifestageName) {
        filterOptionsCreator[tax.lifestageName] = 'a';
      }
    });
  });

  // Turn object with keys to unique array
  const filterOptions = Object.keys(filterOptionsCreator);

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        onFormSubmit({event: e, searchTerm: searchTerm, RCDLState: RCDLState});
      }}
      className="rc-layout-container rc-four-column rc-max-width--md"
      style={{display: 'block', width: '100%', textAlign: 'left', marginBottom: '32px', marginTop: '32px'}}
    >
      <div className="rc-column rc-content-v-middle">
        <SearchFilter
          options={filterOptions}
          setFilter={setFilter}
          currentFilter={currentFilter}
          RCDLState={RCDLState}
        />
      </div>

      <div className="rc-column rc-double-width rc-content-v-middle">
        <div style={{maxWidth: '320px', width: '100%', position: 'relative'}}>
          <ClickListenerWrapper setFocusParam={setFocus}>
            <span className="rc-input rc-full-width">
              <input
                className="rc-input__control rc-full-width"
                id="search-inline-input"
                type="text"
                name="search"
                aria-autocomplete="list"
                autoComplete="off"
                autoFocus={initialFocus}
                style={{height: '48px'}}
                value={searchTerm}
                onChange={e =>
                  setSearchTerm(e.target.value, {
                    refresh: false,
                    shouldClearFilters: false,
                    autocompleteResults: true
                  })
                }
              />

              {/* ToDo: Remove pixel height */}
              <label className="rc-input__label" htmlFor="search-inline-input">
                <span className="rc-input__label-text">Search</span>
              </label>
            </span>
            {renderSuggestions({
              active: focused,
              liClasses: liClasses,
              ulClasses: ulClasses,
              searchTerm: searchTerm,
              autocompletedResults: autocompletedResults
            })}
          </ClickListenerWrapper>
        </div>
      </div>
      <div className="rc-column rc-max-width--sm rc-content-v-middle">
        <button className="rc-btn rc-btn--one rc-full-width" type="submit">
          Search
        </button>
      </div>
    </form>
  );
};

SearchInputInline.propTypes = {
  ulClasses: PropTypes.string.isRequired,
  liClasses: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  autocompletedResults: PropTypes.array,
  RCDLState: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentFilter: PropTypes.string,
  setFilter: PropTypes.func.isRequired
};

/**
 * @description Component that displays search term input.
 * @param {string} liClasses Addtional list item classes.
 * @param {string} ulClasses Addtional list classes.
 * @param {func} setSearchTerm Function to set the search term.
 * @param {string} searchterm Current search term from Swifttype.
 * @param {array} autocompletedResults Array of suggested results.
 * @return {DOMElement} Search input element.
 */
const SearchInputBar = ({liClasses, ulClasses, setSearchTerm, searchTerm, autocompletedResults}) => {
  const [focused, setFocus] = useState(false);
  return (
    <ClickListenerWrapper setFocusParam={setFocus} style={{height: '100%'}}>
      <div style={{height: '100%'}}>
        <input
          className="rc-header__input"
          placeholder="Start typing to search"
          id="search-box-2"
          value={searchTerm}
          onChange={e =>
            setSearchTerm(e.target.value, {
              refresh: false,
              shouldClearFilters: true,
              autocompleteResults: true
            })
          }
          type="search"
        />
        {renderSuggestions({
          active: focused,
          liClasses: liClasses,
          ulClasses: ulClasses,
          searchTerm: searchTerm,
          autocompletedResults: autocompletedResults
        })}
      </div>
    </ClickListenerWrapper>
  );
};

SearchInputBar.propTypes = {
  ulClasses: PropTypes.string.isRequired,
  liClasses: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  autocompletedResults: PropTypes.array
};

export {SearchInputInline, SearchInputBar};
