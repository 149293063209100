import React from 'react';
import PropTypes from 'prop-types';

import {LayoutContainer, Column} from '../layouts';
import {InsightSnippet} from '../templates';
import {NavigationList, LabelButton} from '../common';
import {useMegaMenuNavigation} from '../../static_queries';
import navigationStyles from '../../styles/navigation.module.css';
import typographyStyles from '../../styles/typography.module.css';
import logout from '../../helpers/logout';

/**
 * @description Mega menu for displaying extended navigation.
 * @param {function} toggleModal Method to hide megamenu when link/close button is clicked.
 * @return {DOMElement} Mega menu React element.
 */
const MegaMenu = ({toggleModal}) => {

  if (typeof window === 'undefined') { // Ensure proper server rendering.
    return null;
  }

  const pageWidth = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth; // TODO: Move that to context to pass it as props to other components

  const {navigationList: navigationSections} = useMegaMenuNavigation()[0].node;

  /**
   * @description Render navigation columns.
   * @return {array} Array with navigation columns.
   */
  const renderColumns = () => {
    return navigationSections.map((section, i) => {
      return (
        <li
          key={`${section.id}-mega-menu-${i}`}
          className={`rc-list__item rc-list__item--group ${navigationStyles.group}`}>
          <NavigationList
            section={section}
            extraClasses={{aClass: 'rc-list__link', liClass: 'rc-list__item'}}
            toggleModal={toggleModal}
            pageWidth={pageWidth}
          />
        </li>
      );
    });
  };

  return (
    <LayoutContainer additionalClass="rc-max-width--xl rc-content-h-middle" columns="three" style={{height: '100%'}}>
      <Column additionalClass="rc-lg-up">
        <InsightSnippet handleClick={toggleModal}/>
      </Column>
      <Column
        additionalClass={`rc-nav ${navigationStyles.navigationMain}`}
        customTag="nav" size="double"
        data-toggle-group="mobile"
        data-toggle-effect="rc-expand--horizontal">
        <div className="rc-layout-container rc-three-column">
          <div className="rc-column rc-triple-width rc-padding-x--none--mobile
            rc-padding-right--none rc-padding-bottom--none--mobile">
            <ul className="rc-list rc-list--blank rc-list--align rc-list--three-column" role="menubar">
              {renderColumns()}
              <li className={`rc-list__item rc-list__item--group ${navigationStyles.iconsGroup}`}>
                <ul className="rc-list rc-list--blank rc-list--align rc-btn-offset--top" role="menu">
                  <li className="rc-list__item">
                    <LabelButton
                      icon="user"
                      handleClick={logout}
                      customLabelButton
                      additionalClass={`rc-list__link ${typographyStyles.regularSize}
                        ${typographyStyles.noBorderDesktop}`}>
                        Log out</LabelButton>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </Column>
    </LayoutContainer>
  );
};

MegaMenu.propTypes = {
  toggleModal: PropTypes.func
};

export {MegaMenu};

