import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import createSlug from '../../helpers/createSlug';
import setTitleCase from '../../helpers/setTitleCase';

/**
 * @description Select input component that allows user to choose search filter.
 * @param {array} contextArgs Array with search context information such as filter taxonomies, search index list
 * current selected filter etc.
 * @param {object|array} RCDLState Object that describes load state of RCDL assets.
 * @return {DOMElement} Search filter React element.
 */
const SearchFilter = ({options, RCDLState, setFilter, currentFilter}) => {
  if (!options) {
    return null;
  }

  const select = useRef(null);

  // Sort array options
  options = options.sort();

  useEffect(() => {
    if (typeof RCDLState !== 'undefined') {
      if (select.current && RCDLState[0].RCDLloaded) {
        const internalOptions = {
          itemSelectText: '',
          searchEnabled: false,
          shouldSort: false,
          classNames: {
            itemSelectable: ''
          }
        };
        if (typeof RCDL.features.Selects !== 'undefined') {
          RCDL.features.Selects.init('[data-js-select]', select.current, internalOptions);
        }
      }
    }
  }, [options, RCDLState]);

  return (
    <span className="rc-select rc-full-width">
      <label className="rc-select__label" htmlFor="search-filter-select">
        Filter by:
      </label>
      <select
        value={currentFilter}
        ref={select}
        data-js-select
        id="search-filter-select"
        onChange={e => setFilter(event.target.value)}
      >
        <option disabled value={''}>
          Select a result type
        </option>
        <option key="all" value="all">
          All
        </option>
        {options.map(item => {
          return (
            <option key={createSlug(item)} value={item}>
              {setTitleCase(item)}
            </option>
          );
        })}
      </select>
    </span>
  );
};

SearchFilter.propTypes = {
  options: PropTypes.array.isRequired,
  RCDLState: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setFilter: PropTypes.func.isRequired
};

export {SearchFilter};
