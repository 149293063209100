import React from 'react';
import PropTypes from 'prop-types';

import {TextContentBlock, Image} from '../common';
import {LayoutContainer, Column} from '../layouts';
import blockStyles from '../../styles/block.module.css';

/**
 * @description One of the basic content blocks. Displays two columns, one with the text and one with the image.
 * @param {object} image Data object containg image information.
 * @param {string} summary Summary text.
 * @param {string} title Block title.
 * @param {string} order Defines order of the columns.
 * @param {string} meta Any addtional meta tag above the title.
 * @param {array} buttonsConfig Array of objects to define button configuration.
 * @return {DOMElement} Horizontal Content block React element.
 */
const HorizontalContentBlock = ({
  image,
  summary,
  title,
  order = 'regular',
  meta,
  buttonsConfig,
  additionalWrapperClass = ''
}) => {

  const summaryText = summary.cardSummary ? summary.cardSummary : summary.summary;
  const reverseOrderClass = order === 'regular' ? 'rc-reverse-layout-mobile' : '';
  const reverseOrderColumnClass = order === 'regular' ? 'rc-margin-bottom--xl--mobile' : '';

  return (
    <LayoutContainer
      additionalClass={`rc-max-width--lg rc-padding-bottom--xl ${additionalWrapperClass} ${reverseOrderClass}`}>
      {order === 'reverted' && (
        <Column>
          {image && <Image data={image}/>}
        </Column>
      )}
      <Column align='middle'>
        <TextContentBlock
          meta={meta}
          title={title}
          text={summaryText}
          buttonsConfig={buttonsConfig}
          wrapperClass={blockStyles.horizontalTextBlock}/>
      </Column>
      {order === 'regular' && (
        <Column additionalClass={reverseOrderColumnClass}>
          {image && <Image data={image}/>}
        </Column>
      )}
    </LayoutContainer>
  );
};

HorizontalContentBlock.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.object,
  order: PropTypes.oneOf(['regular', 'reverted']),
  meta: PropTypes.string,
  buttonsConfig: PropTypes.array,
  additionalWrapperClass: PropTypes.string
};

export {HorizontalContentBlock};

