import React from 'react';
import PropTypes from 'prop-types';
import {VerticalContentBlock, HorizontalContentBlock} from '.';
import {LayoutContainer, Column} from '../layouts';
import setButtonConfig from '../../helpers/setButtonConfig';

/**
 * @description Wrapper component for two vertical content blocks - common layout pattern in the project.
 * @param {array} data Array of objects containg data for single vertical block.
 * @return {DOMElement} TwoVerticalContentBlocks React element.
 */
const TwoVerticalContentBlocks = ({data}) => {

  /**
   * @description Renders blocks if there are two data elements.
   * @returns {array} Array of block elements.
   */
  const renderBlocks = () => {
    return data.map((block) => {

      let buttons = [];
      buttons.push(setButtonConfig({
        typeName: block.__typename,
        fields: block.fields,
        cardTitle: block.cardTitle,
        buttonLabel: block.buttonLabel
      }));

      return (
        <Column key={block.cardTitle} additionalClass="rc-margin-bottom--lg--mobile">
          <VerticalContentBlock
            image={block.cardImage}
            summary={block.cardSummary}
            title={block.cardTitle}
            buttonsConfig={buttons}/>
        </Column>
      );
    });
  };

  /**
   * @description Renders single block if there's single element in data.
   * @return {array} React block element.
   */
  const renderSingleBlock = () => {
    let buttons = [];
    const {__typename, fields, cardTitle, buttonLabel, cardImage, cardSummary} = data[0];
    buttons.push(setButtonConfig({
      typeName: __typename,
      fields: fields,
      cardTitle: cardTitle,
      buttonLabel: buttonLabel
    }));
    return (
      <HorizontalContentBlock
        image={cardImage}
        summary={cardSummary}
        title={cardTitle}
        buttonsConfig={buttons}
        order='reverted'/>
    );
  };
  return (
    <LayoutContainer additionalClass="rc-max-width--xl rc-margin-bottom--md">
      {data.length === 1 && renderSingleBlock()}
      {data.length > 1 && renderBlocks()}
    </LayoutContainer>
  );
};

TwoVerticalContentBlocks.propTypes = {
  data: PropTypes.array
};

export {TwoVerticalContentBlocks};
